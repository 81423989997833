import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'

var provider = new firebase.auth.GoogleAuthProvider();

export const loginWithGoogle = async (reloadUser, message) => {
    const setUpUser = firebase.functions().httpsCallable('setUpUser');
    const getActivePrograms = firebase.functions().httpsCallable('getActivePrograms');

    try {
        const result = await firebase.auth().signInWithPopup(provider);
        if (result.additionalUserInfo.isNewUser) {
            const programs = (await getActivePrograms()).data; // Hardcode to current program? 

            if (programs.length) {
                await setUpUser({ displayName: result.user.displayName, programName: programs[0] });
                await firebase.auth().currentUser.getIdToken(true);
                await reloadUser();
                message.success('Just created your user account!')
            }
            else {
                await firebase.auth().currentUser.delete();
                throw new Error("No active programs to sign up to");
            }

        }
    } catch (error) {
        // Handle Errors here.
        console.error('error', error.message);
        message.error(error.message)
    }
}