import React, { useEffect, useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'
import firebase from 'firebase/app';
import 'firebase/auth'
import { notification, message } from 'antd'
import { LoginPage, Container, GoogleButton } from './styles'
import { loginWithGoogle } from './googleLogin'
import LoginForm from './form'

function Login() {
    const [errorVisibility, setErrorVisibility] = useState(false)
    const [errorContent, setErrorContent] = useState({ title: '', message: '' })
    const { reloadUser } = useContext(UserContext)

    useEffect(() => {
      if (errorVisibility) {
        openNotificationWithIcon('error');
      }
    },[errorVisibility])

    const openNotificationWithIcon = type => {
      notification[type]({
        message: errorContent.title,
        description: errorContent.message,
      });

      setTimeout(() => {
        setErrorVisibility(false)
      },5000)
    };

    const loginWithFirebase = ({email, password}) => {
      firebase.auth().signInWithEmailAndPassword(email, password)
      .then(() => {
        //setUserAuth(true)
      })
      .catch((error) => {
        //var errorCode = error.code;
        var errorMessage = error.message;

        setErrorContent({
          'title': 'Error in Sign In',
          'message': errorMessage
        })

        setErrorVisibility(true)
      });
    }
      
    return (
        <LoginPage>
            <Container>
              <h2>Log In</h2>
              <GoogleButton type="primary" shape = "round" onClick={()=>{loginWithGoogle(reloadUser, message)}}>
                Google
              </GoogleButton>
              <LoginForm
                  loginWithFirebase = { loginWithFirebase } 
                  setErrorContent = { setErrorContent } 
                  setErrorVisibility = { setErrorVisibility }
              />
            </Container>
        </LoginPage>
    )
}

export default Login