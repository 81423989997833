import React, {useState, useEffect} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/functions';
import { List, Button, Popconfirm, Space, Skeleton, Avatar, message} from 'antd';
import { UserOutlined } from '@ant-design/icons'
import { UserManagementPage, PhotoNameSpace } from './styles'


function UserManagement() {
  const [loading, setLoading] = useState(true);                      
  const [members, setMembers] = useState([]);                   

  const db = firebase.firestore();

  useEffect(()=>{
    const unsub = db.collection('profiles').onSnapshot(loadMembers);
    return unsub;
  }, [])

  const loadMembers = (snapshot) => {
    let newData = snapshot.docs.map(doc => {
        const data = doc.data();
        data.key = doc.id;
        data.id = doc.id;
        return data;
    });

    setMembers(newData);
    setLoading(false);
  }

  const handleElimination = async (uid) => {
    const deleteUser = firebase.functions().httpsCallable('deleteUserByCall');

    try {
        await deleteUser({uid: uid});
        message.success("User deleted successfully!")
    }

    catch(error) {
        console.log(error);
        message.error(error.message);
    }
  } 
  
  return (
    <UserManagementPage>
        { loading ? 
            (
                <>
                <Skeleton.Input active className='loader-skeleton'/>
                <Skeleton.Input active className='loader-skeleton'/>  
                <Skeleton.Input active className='loader-skeleton'/>   
                </>
            ) :
            <List
                bordered
                dataSource={ members }
                renderItem={ item => (
                <List.Item>
                        <Space size='large' className='item-container'>
                            <PhotoNameSpace>
                            { item.photoURL ?
                                <Avatar size={32} src={item.photoURL} style={{marginRight: "10px"}}/>
                                : <Avatar size={32} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/> }
                                {item.displayName || item.email }
                            </PhotoNameSpace>
                            <Space>
                                <Popconfirm
                                    title="Are you sure you want to delete this user?"
                                    onConfirm={ () => handleElimination(item.id) }
                                    okType="danger"
                                    okText="Yes"
                                    cancelText="No"
                                >   
                                    <Button loading={loading} danger>Delete</Button>
                                </Popconfirm>
                            </Space>
                        </Space>
                </List.Item>
                )}
            />
        }
    </UserManagementPage>
    )
}

export default UserManagement
