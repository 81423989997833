import styled from 'styled-components';
import { Row, Modal, Menu, Layout, Space } from 'antd';

export const CommunityPage = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const Container = styled.div `
    display: flex;
    flex-direction: column;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
    width: 90%;
    min-width: 60vw;
    height: 85vh;
    overflow-y: auto;
`

export const CardContainer = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding: 10px 20px 10px 20px;
    margin-top: 20px;
    width: 290px;
    max-height: 200px;
    white-space: nowrap;
    text-overflow: clip;
    -webkit-box-shadow: 0px 0px 8px #eee;
    -moz-box-shadow: 0px 0px 8px #eee;
    box-shadow: 0px 0px 8px #eee;
    cursor: pointer;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);

    h3 {
        padding-top: 5px;
        margin-bottom: 0;
        font-weight: 700;
    }
    p {
        color: gray;
        margin-bottom: 0;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`

export const FullRow = styled(Row)
`
    width:100%;
    border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
    margin-bottom: ${props => props.separator ? "30px" : "0px"};
    margin-top: ${props => props.bottom ? "15px" : "0px"};
`

export const ContactModal = styled(Modal)
`

`

export const QuoteP = styled.p `
    text-align: justify;
    font-style: italic;
`

export const MenuContainer = styled.div `
    display: flex;
    width: 100%;
    min-height: 100vh;
    box-shadow: 0px 0px 12px rgba(0,0,0,0.15);
`

export const StyledSider = styled(Layout.Sider)`
    width: 256px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    .ant-layout-sider-zero-width-trigger {
        top: 0
    }
`

export const StyledMenu = styled(Menu)`
    padding: 20px 0px 0px 0px;
    box-shadow: 4px 0px 5px rgba(0,0,0,0.1);
    font-size: 16px;
    width: 200px;
    position: relative;

    .ant-menu-item {
        height: 60px;
        display: flex;
        padding-left: 25px;
        align-items: center;
        
        &.back{
            position: absolute;
            bottom: 0;
            border-top: 1px solid rgba(0,0,0,0.1);
            width: 100%;
            padding-left: 50px;
        }
    }
`

export const Content = styled.div `
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`

export const UserManagementPage = styled.div `
    margin-top: 20px;
    max-height: 80vh;
    overflow-y: auto;
`

export const PhotoNameSpace = styled(Space) `
    width: 30vw;
`