import React, {useContext} from 'react'
import { UserContext } from '../../contexts/UserContext';
import { WhiteContainer } from './styles'
import { Tabs } from 'antd'
import firebase from 'firebase/app';
import 'firebase/functions';
import EventForm from './eventForm';
import ResourceForm from './resourceForm';

function Add() {
    const { selectedProgram } = useContext(UserContext) 
    const addEvent = firebase.functions().httpsCallable('addEvent');
    const addResource = firebase.functions().httpsCallable('addResource');
    
    const submitEvent = async (data) => {
        data.date = Number(data.date);
        await addEvent(data);
    };

    const submitResource = async (data) => {
        await addResource({...data, programName: selectedProgram});
    };

    return(
        <WhiteContainer>
                <h2>Add event or resource</h2>
                <Tabs type="card">
                    <Tabs.TabPane tab="Event" key="1">
                        <EventForm onFinish={submitEvent}/>
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Resource" key="2">
                        <ResourceForm onFinish={submitResource}/>
                    </Tabs.TabPane>
                </Tabs>
        </WhiteContainer>
    )
}

export default Add