import React from 'react'
import { ChartContainer } from './styles'
import { Bar } from 'react-chartjs-2'

function AvgInterviewsChart({xData, customer, group}) {

    const data = {
      labels: xData,
      datasets: [
        {
          label: 'Customer Interviews',
          data: customer,
          backgroundColor: 'rgb(75, 192, 192)',
        },
        {
          label: 'Group Avg',
          data: group,
          backgroundColor: 'rgb(54, 162, 235)',
        }
      ],
    }
    
    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [ {
          scaleLabel: {
            display: true,
            labelString: 'Week'
          } 
        } ],
      }
    }

    return(
          <ChartContainer className="chart-container">
              <Bar data={data} options={options}/>
          </ChartContainer>
    )
}

export default AvgInterviewsChart