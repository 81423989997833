import React, { useState, useEffect, createContext } from 'react'
import firebase from 'firebase/app';
import 'firebase/auth';
import ProgramsService from '../services/ProgramsService';

const initialState = {
    userAuth: false,
    userInfo: null
}

export const UserContext = createContext({...initialState})

function UserContextProvider(props) {
    const [userAuth, setUserAuth] = useState(firebase.auth().currentUser ? true : false)
    const [userInfo, setUserInfo] = useState(undefined)
    const [selectedProgram, setSelectedProgram] = useState(undefined)

    useEffect(()=> {
        firebase.auth().onAuthStateChanged(firebaseUser => loadUser(firebaseUser))
    }, []);

    const loadUser = async (firebaseUser) => {
        if (firebaseUser) {
            const result = await firebaseUser.getIdTokenResult()

            //ELIMINATE THE MANUAL GRADUATE CLAIMS AFTER TESTING!!! (Overwrites participants to graduates)
            // result.claims.graduate = true
            // result.claims.participant = false
            
            //var currentUser = {...firebaseUser, claims: result.claims }

            var currentUser = {
                uid: firebaseUser.uid,
                photoURL: firebaseUser.photoURL,
                displayName: firebaseUser.displayName,
                email: firebaseUser.email,
                claims: result.claims
            }

            if (userInfo === currentUser) return;

            if (result.claims.admin) {
                const programs = await ProgramsService.getAllPrograms();
                setSelectedProgram(programs[0])
            }
            else setSelectedProgram(result.claims.programName)

            setUserInfo(currentUser);
            setUserAuth(true);
        }
        else {
            setUserAuth(false);
            setUserInfo(undefined);
        }
    };
      
    const reloadUser = async () => {
        await firebase.auth().currentUser?.reload()
        return loadUser(firebase.auth().currentUser);
    }

    return(
        <UserContext.Provider value = {{userAuth, userInfo, reloadUser, selectedProgram, setSelectedProgram}}>
            {props.children}
        </UserContext.Provider>
    )
}

export default UserContextProvider