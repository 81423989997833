import styled from 'styled-components'
import { Button } from 'antd'

export const LoginPage = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const Container = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px 20px 40px;
    margin-top: 10%;
`

export const GoogleButton = styled(Button)
`
    margin: 10px 0 25px;
`

export const LoginButton = styled(Button)
`
    width: 100%
`

export const ForgotPassword = styled.a `
    text-decoration: none;
    color: black;
`