import React, { useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import EventsCalendar from './calendar'
import ResourcesList from './resourceList'
import Add from './add'
import { CalendarOutlined, BarsOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { Container, StyledSider, StyledMenu, Content } from './styles'

function Resources() {
    const [menuOption, setMenuOption] = useState('events')
    const { userInfo } = useContext(UserContext) 

    const handleMenuClick = (e) => {
        setMenuOption(e.key)
    }

    return(
        <Container>
            <StyledSider 
                theme='light'
                width={200}
                breakpoint="md"
                collapsedWidth="0"
                zeroWidthTriggerStyle={{border: "1px solid lightgray"}}
                >
                <StyledMenu
                    theme = 'light'
                    onClick = { handleMenuClick }
                    mode = "inline"
                    defaultSelectedKeys={['events']}
                >
                        <StyledMenu.Item key='events' icon = { <CalendarOutlined /> }>
                            Events 
                        </StyledMenu.Item>
                        <StyledMenu.Item key='resources' icon = { <BarsOutlined /> }>
                            Resources
                        </StyledMenu.Item>
                        { userInfo.claims.admin && 
                            <StyledMenu.Item key='add' icon = { <PlusCircleOutlined /> }>
                                Add
                            </StyledMenu.Item>         
                        }
                </StyledMenu>
            </StyledSider>
            <Content>
                {
                    menuOption === 'events' ? <EventsCalendar/> : 
                    (menuOption === 'resources' ? <ResourcesList/> : 
                    userInfo.claims.admin && <Add/>
                    )
                }
            </Content>
        </Container>
    )
}

export default Resources