import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/functions'
import { Spin, Col, Statistic, Tooltip, message } from 'antd'
import { LoadingOutlined, ArrowDownOutlined, ArrowUpOutlined, FrownOutlined, SmileOutlined } from '@ant-design/icons'
import { FullRow, KarmaPage, Container, StatTitle, HeaderTitleCol, ResKarmaCol } from './styles'
import KarmaChart from './karmaChart'
import KarmaForm from './karmaForm'


const givenKarmaTip = "These are the Karma Points you give to other users in this form. It is automatically submitted every week, and if you don't give out any Karma Points, you get penalized by not getting points that week.";
const receivedKarmaTip = "These are the Karma Points other users have given you throughout the program, without including the extra Karma";
const extraKarmaTip = "These are the extra Karma Points you got when a penalized user's Karma Points were distributed among other participants.";
const lostKarmaTip = "These are the Karma Points you lost for not submitting Karma Points in one or more weeks.";
const historicKarmaTip = "These are the Karma Points you've historically assigned other participants. It will help you see if everyone is getting fairly rewarded for their actions towards your idea"

function KarmaOverview() {
    const getCurrentCycleKarmaPoints = firebase.functions().httpsCallable('getCurrentCycleKarmaPoints');
    const getCurrentWeekGivenPoints = firebase.functions().httpsCallable('getCurrentWeekGivenPoints');
    const assignKarmaPoints = firebase.functions().httpsCallable('assignKarmaPoints');

    const [ chartData, setChartData ] = useState([]);
    const [ formData, setFormData ] = useState([]);
    const [ statData, setStatData ] = useState({});
    const [ statLoading, setStatLoading ] = useState(false);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ chartLoading, setChartLoading ] = useState(false);


    useEffect(()=>{
      let mounted = true;
      loadChartData().then((data)=>{if (mounted) {
        setChartData(data.givenPointsBreakdown);
        setStatData({
          karmaReceived: data.totalReceivedPoints,
          karmaGiven: data.totalGivenPoints,
          extraKarma: data.totalExtraPoints,
          lostKarma: data.totalLostPoints
        });
      }});
      loadFormData().then((data)=>{if (mounted) setFormData(data)});

      return () => {mounted = false}
    }, [])

    const loadChartData = async () => {
      try {
        setChartLoading(true);
        setStatLoading(true);
        const res = (await getCurrentCycleKarmaPoints()).data
        setStatLoading(false);
        setChartLoading(false);
        return res;
      }
      catch (error) {
        message.error(error.message);
        console.error(error);
      }
    }

    const loadFormData = async () => {
      try {
        setFormLoading(true);
        const res = (await getCurrentWeekGivenPoints()).data
        setFormLoading(false);
        return res;
      }
      catch (error) {
        message.error(error.message);
        console.error(error);
      }
    }

    const submitForm = async (values) => {
      try {
        setFormLoading(true);
        console.log(values);
        await assignKarmaPoints({karmaPoints: values});
        message.success("Saved!")
        setFormLoading(false);
      }
      catch (error) {
        message.error(error.message);
        console.error(error);
      }

    }
    
    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <KarmaPage>
            <Container>
              <FullRow separator='true' header='true'>
                <HeaderTitleCol xs={24} sm={12} lg={12}>
                  <h2>Karma Overview</h2>
                </HeaderTitleCol>
                <Col xs={12} sm={6} lg={3}>
                  <Tooltip
                    placement="bottomLeft"
                    title={givenKarmaTip}>
                    <Statistic 
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Karma Given</StatTitle>}
                      prefix={<ArrowUpOutlined />} 
                      value={statData.karmaGiven} />
                  </Tooltip>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Tooltip
                    placement="bottomLeft"
                    title={receivedKarmaTip}>
                    <Statistic
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Karma Received</StatTitle>}
                      prefix={<ArrowDownOutlined />} 
                      value={statData.karmaReceived - statData.extraKarma} />
                  </Tooltip>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Tooltip
                    placement="bottomLeft"
                    title={extraKarmaTip}>
                    <Statistic
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Extra Karma</StatTitle>}
                      prefix={<SmileOutlined />} 
                      value={statData.extraKarma} />
                    </Tooltip>
                </Col>
                <Col xs={12} sm={6} lg={3}>
                  <Tooltip
                    placement="bottomLeft"
                    title={lostKarmaTip}>
                    <Statistic
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Lost Karma</StatTitle>}
                      prefix={<FrownOutlined />} 
                      value={statData.lostKarma} />
                  </Tooltip>
                </Col>
              </FullRow>
              <FullRow>
                <ResKarmaCol lg={12} xs={24} separator='true'> 
                  <Spin indicator={spinnerIcon} spinning={chartLoading}>
                    <KarmaChart data={chartData} historicKarmaTip = {historicKarmaTip}/>
                  </Spin>
                </ResKarmaCol>
                <ResKarmaCol lg={12} xs={24}>
                  <Spin indicator={spinnerIcon} spinning={formLoading}>
                    <KarmaForm maxPoints={100} data={formData} onFinish={submitForm}/>
                  </Spin>
                </ResKarmaCol>
              </FullRow>
            </Container>
        </KarmaPage>
    )
}

export default KarmaOverview