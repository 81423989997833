import React, { useState, useEffect } from 'react'
import { Avatar, Form, InputNumber, Button, Col, Tooltip, message } from 'antd'
import { UserOutlined} from '@ant-design/icons';
import { FullRow, FormPointsCol } from './styles'

function KarmaForm({maxPoints, data, onFinish}){
   const [ total, setTotal ] = useState(0);
   const [ form ] = Form.useForm();


   useEffect(()=>{
      const values = Object.values(form.getFieldValue());
      if (values.length > 0) setTotal(values.reduce((total,i)=>total+i))
   }, [data])

   const addToTotal = (val) => {
      if(typeof val == 'number'){
         const values = Object.values(form.getFieldValue());
         if (values.length > 0) setTotal(values.reduce((total,i)=>total+i))
      }
   }

   const totalValidator = async () => {
      if (total < 0) {
         return Promise.reject(new Error('Point distribution error'));
      }
   } 

   const handleSubmit = async (values) => {
      if (total < maxPoints) {
         return message.error("Please distribute all your points");
      }
      if (total > maxPoints) {
         return message.error("Please distribute less than "+maxPoints+" points");
      }

      return onFinish(values);
   }

   const label = (photoURL, name) => <>
   
      {photoURL ?
      <Avatar size={32} src={photoURL} style={{marginRight: "10px"}}/>
      : <Avatar size={32} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/>}
      {name}
      </>

   return(<>
        <FullRow>
           <Col xs={24} sm={16}>
              <h2>Give Karma</h2>
           </Col>
           <Tooltip placement="topLeft" title={`You only have ${maxPoints} points to give out, so use them wisely!`}>
            <FormPointsCol xs={24} sm={8} style={{fontWeight: 'bold'}}>
                  <b style={{color: `${total !== maxPoints ? 'red' : 'black'}`}}>{total}</b> / {maxPoints}
            </FormPointsCol>
           </Tooltip>
        </FullRow>
        <Form
            labelCol={{span:16}}
            wrapperCol={{span:8}}
            labelAlign="left"
            form={form}
            onFinish ={handleSubmit}
            >
            {data && data.map((participant) => 
               <Form.Item
                  key={participant.uid}
                  label={label(participant.photoURL, participant.displayName)}
                  name={participant.uid}
                  initialValue={participant.points}
                  rules={[{ required: true, message: "Value required"}, { validator: totalValidator }]}
               >
                  <InputNumber min={0} parser={value => value.split(".")[0]} onChange={addToTotal} />
               </Form.Item>
            )}
            <Form.Item wrapperCol={{span:24}}>
               <Button htmlType = 'submit' type = 'primary' size = 'large'>Save</Button>
            </Form.Item>
        </Form>
   </>)
}

export default KarmaForm