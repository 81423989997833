import React from 'react'
import { Form, Input } from 'antd'
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { LoginButton, ForgotPassword } from './styles'

function LoginForm({ loginWithFirebase, setErrorContent, setErrorVisibility }){

    const onFinish = (values) => {
      loginWithFirebase({
          email: values.email,
          password: values.password
      })
    };

    const onFinishFailed = (errorInfo) => {
        setErrorContent({
            'title': 'Error with log in',
            'message': errorInfo
        })

        setErrorVisibility(true)
    };
        
    return(
        <Form
            name="login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            className="registration-form"
            size='large'
          >
            <Form.Item
              name="email"
              className="input"
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email"/>
            </Form.Item>
      
            <Form.Item
              name="password"
              className="input"
              rules={[{ required: true, message: 'Please input your password!' }]}
            >
              <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password"/>
            </Form.Item>
            <Form.Item>
              <ForgotPassword href="/reset">
                Forgot password?
              </ForgotPassword>
            </Form.Item>

            <Form.Item>
              <LoginButton type="primary" htmlType="submit">
                Log In
              </LoginButton>
            </Form.Item>
        </Form>
    )
}

export default LoginForm