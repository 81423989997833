import React, { useState, useEffect } from 'react'
import { Container } from '../styles'
import {
    FlexRow,
    ChartCard,
    EmptyContainer
} from './styles'
import { Card, Button, Checkbox, Spin, message, List } from 'antd'
import DashboardService from '../../../services/DashboardService'

import GoalsChart from '../common/goalsChart'
import InterviewsChart from '../common/interviewsChart'
import RevenueChart from '../common/revenueChart'

export default function GraduateDashboard() {
    const [tasks, setTasks] = useState([])
    const [events, setEvents] = useState([])
    const [interviews, setInterviews] = useState([])
    const [weeks, setWeeks] = useState([])
    const [revenue, setRevenue] = useState([])
    const [loadingPage, setLoadingPage] = useState(false)
    const [goals, setGoals] = useState({
        total: [],
        completed: []
    })

    useEffect(()=> {
        initialLoad()
    },[])

    const initialLoad = ()=> {
        setLoadingPage(true)
        Promise.all([getTasks(), getProgressData(), getEvents()]).then(response=> {
            setLoadingPage(false)
        })
    }

    const getTasks = async()=> {
        const newTasks = await DashboardService.getCurrentGoals()
        setTasks([...newTasks])
    }

    const getProgressData = async()=> {
        let data = await DashboardService.getHistoricUserProgress()
        data = {
            ...data,
            weeks: []
        }
        let weeks = []
        for(let i  = 0; i < data.historic.interviews.length; i++){
            weeks.push(i+1)
        }
        setWeeks(weeks)
        setInterviews(data.historic.interviews)
        setGoals({
            total: [...data.historic.goals],
            completedGoals: [...data.historic.completedGoals]
        })
        setRevenue(data.historic.revenue)
    }

    const getEvents = async()=> {
        const events = await DashboardService.getUpcomingEvent()
        setEvents(events)
    }

    const updateTask  = async(event, index)=> {
        setLoadingPage(true)
        let updatedTasks = [...tasks]
        updatedTasks[index].checked = event.target.checked
        await DashboardService.updateCurrentGoals({currentGoals: updatedTasks})
        setTasks([...updatedTasks])
        setLoadingPage(false)
        message.success('Tasks updated')
    }

    
    return (
        <Spin spinning = {loadingPage}>
            <FlexRow>
                <Card
                    title = 'You weekly tasks'
                >
                    {
                        tasks.length > 0 &&
                        tasks.map((task, index)=> {
                            return (
                                <Checkbox
                                    checked = {task.checked}
                                    onChange = {(event)=>updateTask(event, index)}
                                    key = {index}
                                >
                                    {task.summary}
                                </Checkbox>
                            )
                        })
                    }
                </Card>
                <Card
                    title = 'Upcoming Events'
                >
                    {
                        events.length > 0
                        ?<List
                            dataSource = {events}
                            renderItem = {(item)=> {
                                return(
                                    <List.Item key = {item.key}>
                                        <h4>{item.summary}</h4>
                                        <p>{new Date(item.date).toLocaleDateString()}</p>
                                    </List.Item>
                                )
                            }}
                        />
                        :<EmptyContainer>
                            <h3>No upcoming events</h3>
                        </EmptyContainer>
                    }
                </Card>
            </FlexRow>
            <FlexRow>
                <ChartCard title = 'Goals'>
                    {goals.total.length > 0 && weeks.length > 0 && <GoalsChart xData = {weeks} completed = {goals.completedGoals} total = {goals.total} />}
                </ChartCard>
                <ChartCard title = 'Interviews'>
                    <InterviewsChart xData  = {weeks} customer = {interviews}/>
                </ChartCard>
                <ChartCard title = 'Revenue'>
                    <RevenueChart xData  = {weeks} revenue = {revenue}/>
                </ChartCard>
            </FlexRow>
        </Spin>
    )
}
