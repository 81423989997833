import React, { useState, useEffect } from 'react'
import {
    Body,
    PageHeader,
    ProgramContainer,
    ProgramCard
} from './styles'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Spin,  Statistic, message } from 'antd'
import ProgramsService from '../../services/ProgramsService'
import CreateProgramModal from './createProgramModal'


export default function Programs() {
    const [programs, setPrograms] = useState()
    const [activePrograms, setActivePrograms] = useState()
    const [loadingPage, setLoadingPage] = useState(true)
    const [modalVisible, setModalVisible] = useState(false)


    useEffect(()=> {
        getPrograms()
    },[])


    const getPrograms = async()=> {
        Promise.all([getAllPrograms(), getActivePrograms()]).then(response=> {
            setLoadingPage(false)
        })
        .catch((error)=> {
            setLoadingPage(false)
        })
    }

    const getAllPrograms = async()=> {
        const programs = await ProgramsService.getAllPrograms()
        console.log('All programs', programs)
        setPrograms(programs)
    }

    const getActivePrograms = async()=> {
        const programs = await ProgramsService.getActivePrograms()
        setActivePrograms(programs)
    }

    const graduateCohort = async(programName)=> {
        setLoadingPage(true)

        const success = await ProgramsService.graduateProgram(programName)
        if (success) {
            setActivePrograms(activePrograms.filter((program) => program !== programName))
            message.success("Cohort graduated!")
        }

        setLoadingPage(false)
    }

    const createNewProgram = async (values)=> {
        setModalVisible(false)
        setLoadingPage(true)

        console.log("New program:", values)

        const success = await ProgramsService.createNewProgram(values)
        if (success) {
            setPrograms([...programs, values.programName])
            setActivePrograms([...activePrograms, values.programName])
            message.success(`Created new program: ${values.programName}`)
        }

        setLoadingPage(false)
    }

    const cancelNewProgram = ()=> {
        setModalVisible(false)
    }

    return (
        <Body>
            <CreateProgramModal
                visible = {modalVisible}
                createNewProgram = {createNewProgram}
                cancelNewProgram = {cancelNewProgram}
            />
            <PageHeader>Accelerator Programs</PageHeader>
            <Spin spinning = {loadingPage}>
                <ProgramContainer>
                    <div className = 'header'>
                        <Button
                            type = 'primary'
                            icon = {<PlusOutlined />}
                            onClick = {()=> setModalVisible(true)}
                        >Create New Program</Button>
                    </div>
                    {
                        programs && activePrograms && programs.length > 0 &&
                        programs.map((programName, index)=> {
                            const active = activePrograms.includes(programName)
                            return(
                                <ProgramCard key = {index}>
                                    <Statistic
                                        title = 'Program Name'
                                        value = {programName}
                                    />
                                    {active && <Button type = 'primary' onClick = {()=> graduateCohort(programName)}>Graduate Cohort</Button>}
                                </ProgramCard>
                            )
                        })
                    }

                </ProgramContainer>
            </Spin>
        </Body>
    )
}
