import React, { useEffect, useState } from 'react'
import { Form, Input, Button, Col, Row, message, Select, notification } from 'antd'
import { MailOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';

function SignupForm({signUpWithFirebase, getActivePrograms}){

    const [programs, setPrograms] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let mounted = true
        getActivePrograms().then((res) => { 
            if (mounted) {
                setPrograms(res.data)
                setLoading(false)
            }
        })

        return () => mounted = false
    }, [])
    

    const onFinish = values => {
        signUpWithFirebase({
            email: values.email,
            password: values.password,
            name: values.name,
            programName: values.programName
        })
        .then(() => {
            message.success('Just created your user account!')
        })
        .catch((error) => {
            notification.error({message: "Sign up error", description: error.message})
        })
    };
    
    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };

   return(
        <Form
        layout='vertical'
        name="basic"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        size='large'
        className = "registration-form"
    >
        <Row align='top' justify='space-between' gutter={16}>
        <Col xs={24} md={12}>
            <Form.Item
            name="email"
            className="input"
            rules={[
            {type: 'email', message: 'The input is not valid email!'},
            { required: true, message: 'Please input your email!' }]}
            >
            <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email"/>
            </Form.Item>
        </Col>
        <Col xs={24} md={12}>
            <Form.Item
            name="name"
            className="input"
            rules={[{ required: true, message: 'Please input your name!' }]}
            >
            <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Full name"/>
            </Form.Item>
        </Col>
        </Row>

        <Row align='top' justify='space-between' gutter={16}>
        <Col xs={24} md={12}>
            <Form.Item
                name="password"
                className="input"
                rules={[{ required: true, message: 'Please input your password!' }]}
            >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Password"/>
            </Form.Item>
        </Col>

        <Col xs={24} md={12}>
            <Form.Item
                name="password-confirm"
                className="input"
                dependencies={['password']}
                hasFeedback
                rules={[
                    { required: true, message: 'Please confirm your password!' },
                    ({ getFieldValue }) => ({
                    validator(rule, value) {
                        if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                        }
                        return Promise.reject('The two passwords that you entered do not match!');
                    },
                    }),
                ]}
            >
                <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} placeholder="Confirm Password"/>
            </Form.Item>
        </Col>
        </Row>
        <Form.Item
            name="programName" 
            rules={[{ required: true, message: 'Select a program' }]}
        >
            <Select placeholder="Select a Program" loading={loading}>
                {programs.map((program)=>
                    <Select.Option value={program} key={"program-"+program}>
                        {program}
                    </Select.Option>
                )}
            </Select>
        </Form.Item>

        <Form.Item className="button-container" style={{textAlign: "center"}}>
        <Button type="primary" htmlType="submit" className="submit-btn">
            Create Account 
        </Button>
        </Form.Item>
    </Form>
   )
}

export default SignupForm