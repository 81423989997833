import React, { useEffect } from 'react'
import { Avatar, Col, Progress, Tooltip } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { FullRow, BarPoints } from './styles'

function KarmaChart({data, historicKarmaTip}){

   const pointsArray = data ? data.map(part => part.points) : []
   const maxPoints = Math.max(...pointsArray) || 100;

   useEffect(()=> {
      console.log('tooltip text', historicKarmaTip)
   },[])

   return(
      <>
         <FullRow mb='17'>
            <Col span={24}>
               <Tooltip
                  placement='topLeft'
                  title = {historicKarmaTip}
               >
                  <h2>Historical Log (Karma given)</h2>
               </Tooltip>
            </Col>
         </FullRow>
         {data && data.map((participant) => 
            <FullRow mb='20' key={participant.uid}>
               <Col lg={10} md={9} xs={9}>
                  { participant.photoURL ?
                     <Avatar size={32} src={participant.photoURL} style={{marginRight: "10px"}}/>
                     : <Avatar size={32} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/> }
                  {participant.displayName}
               </Col>
               <Col lg={10} md={8} xs={8}>
                  <Progress
                     strokeColor={{
                        from: '#108ee9',
                        to: '#87d068',
                     }}
                     percent={(participant.points * 100)/maxPoints }
                     status="active"
                     showInfo={false}
                  />
               </Col>
               <Col lg={4} md={6} xs={6}>
                  <BarPoints>{participant.points}</BarPoints>
               </Col>
            </FullRow>
         )}
      </>
   )
}

export default KarmaChart