import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow } from './styles'
import { Space, Avatar, Table, Spin, Checkbox, message, InputNumber, Input } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';

function EvaluationBox({evalId, evalType, record, selectedProgram}) {
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState(false);

    const evaluateParticipant = firebase.functions().httpsCallable('evaluateParticipant');

    const handleEvaluation = async (record, value) => {
        if (typeof value !== 'number' && typeof value !== 'boolean') {
            setError(true);
            return message.error('Please enter a number');
        }

        else setError(false);

        try {
            setLoading(true);
            console.log(record.uid, value, evalId);
            await evaluateParticipant({uid: record.uid, evaluationId: evalId, value: value, programName: selectedProgram});
            message.success("Evaluated successfully!");
            setLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false);
        }
    }

    return(
        <Spin spinning={loading} size="small">
            <div style={{textAlign:"center"}}>
            { evalType === "checkbox" ?
                <Checkbox
                    defaultChecked={record.breakdown[evalId] ? true : false}
                    onChange={(e)=>{handleEvaluation(record, e.target.checked)}}
                />
            :
                <div className={error ? 'ant-form-item-has-error' : ''}>
                    <InputNumber
                        min={0} defaultValue={record.breakdown[evalId]}
                        onChange={(value)=> handleEvaluation(record, value)}
                    />
                </div>
            }
            </div>
        </Spin>
    )
}

const baseCol = [
    {
      title: 'Participant',
      dataIndex: 'participant',
      key: 'participant',
      render: (text, record) => (
        <Space size="middle">
            { record.photoURL ?
                <Avatar size={32} src={record.photoURL} style={{marginRight: "10px"}}/>
                : <Avatar size={32} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/> }
            {record.displayName}
        </Space>
      ),
    }        
]

function Evaluate({selectedProgram}) {
    const [ loading, setLoading ] = useState(true);
    const [ columns, setColumns ] = useState(baseCol);
    const [ data, setData ] = useState([]);

    const getParticipants = firebase.functions().httpsCallable('getParticipantsPersistenceResults');
    const db = firebase.firestore();

    useEffect(()=>{
        const unsub = db.collection('programs').doc(selectedProgram).collection('persistenceEvaluations').where("enabled", "==", true).orderBy('description').onSnapshot(loadEvaluations);
        return unsub;
    }, [])

    const loadEvaluations = (snapshot) => {
        let newCols = snapshot.docs.map(doc => {
            const data = doc.data();
            return { title: data.description,
                     dataIndex: doc.id,
                     key: doc.id,
                     render: (_, record) => (
                        <EvaluationBox evalId={doc.id} evalType={data.type} record={record} selectedProgram={selectedProgram}/>
                    )}
        });
        setColumns([...baseCol, ...newCols])
    }
    
    useEffect(() => {
        let mounted = true;
        loadParticipants().then(data => {if (mounted) setData(data)});
        return () => {mounted = false}
    },[])

    const loadParticipants = async () => {
        try {
            setLoading(true);
            const res = (await getParticipants({programName:selectedProgram})).data;
            const data = res.map(user => {
                user.key = user.uid;
                return user;
            });
            console.log(data)
            setLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false)
            return []
        }
    }

    return(
        <WhiteContainer>
            <FullRow separator='true'>
              <h2>Evaluate</h2>
            </FullRow>
            <Table
                style={{overflowX: "auto", overflowY: 'hidden'}}
                bordered
                pagination={false}
                loading={loading} 
                columns={columns}
                dataSource={data}
                size="small"
            />
        </WhiteContainer>
    )
}

export default Evaluate