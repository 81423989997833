import React, { useContext } from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/functions'
import { UserContext } from '../../contexts/UserContext'
import { SignupPage, Container } from './styles'
import SignupForm from './form'

function Signup() {
    const setUpUser = firebase.functions().httpsCallable('setUpUser');
    const getActivePrograms = firebase.functions().httpsCallable('getActivePrograms');
  
    const { reloadUser } = useContext(UserContext);

    const signUpWithFirebase = async ({email, password, name, programName}) => {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      try {
        await setUpUser({displayName: name, programName: programName});
        await firebase.auth().currentUser.getIdToken(true);
        await reloadUser();
      }
      catch (error) {
        await firebase.auth().currentUser.delete()
        throw new Error(error.message)
      }
    }

    return(
        <SignupPage>
            <Container>
              <h2>Sign Up</h2>
              <SignupForm
                  signUpWithFirebase = { signUpWithFirebase } 
                  getActivePrograms = { getActivePrograms }
              />
            </Container>
        </SignupPage>
    )
}

export default Signup