import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Form, Input, InputNumber, Checkbox, Button, Row, Col, message, Modal, Spin, Switch } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import 'firebase/functions'

var lastGoals = []

function ProgressModal({visible}){
    const getLastGoals = firebase.functions().httpsCallable('getLastGoals');
    const storeUserProgress = firebase.functions().httpsCallable('storeUserProgress');

    const [ goalsUpdate, setGoalsUpdate ] = useState(Date.now());
    const [ loader, setLoader ] = useState(false);
    const [ form ] = Form.useForm();
    const history = useHistory()

    useEffect(() => {
        if(visible) {
            console.log("Loading progress modal...")
            loadLastGoals();
        }
        else {
            if (loader) history.push("/dashboard/"+Date.now());
        }
    }, [visible])

    const loadLastGoals = async () => {
        try {
            setLoader(true);
            var res = (await getLastGoals()).data;
            lastGoals = res;
            setGoalsUpdate(Date.now());
            setLoader(false);
        }
        catch (error) {
            message.error("Error: Please contact support");
            message.error(error.message);
            console.error(error);
        }
    }

    const handleGoalCheck = (value, i) => {
        lastGoals[i].checked = value;
        setGoalsUpdate(Date.now());
    }

    const handleSubmit = async (values) => {
        setLoader(true);
        values.newGoals = values.newGoals.map(str => {return {summary: str, checked: false}})
        values.lastGoals = lastGoals
        console.log(values);

        //Overwrites for eliminating the interviews and revenue
        values.lastInterviews = 0
        values.lastRevenue = 0

        try {
            await storeUserProgress(values);
            message.success("Progress saved!")
        }
        catch (error) {
            message.error("Error: Please contact support");
            message.error(error.message);
            console.error(error);
        }
    }

   return(
      <Modal style={{top:"40px"}} destroyOnClose visible={visible} footer={null} closable={false} title="Track your Progress!">
      <Spin tip="Please wait..." spinning={loader} >    
        <Form
            form={form}
            preserve={false}
            layout="vertical"
            onFinish ={ handleSubmit }
            style={{width: "100%"}}
            >
            <Row gutter={16}>
                {/* <Col span={24}>
                    <Form.Item
                        label = {<b>How much did you earn last week?</b>}
                        name='lastRevenue'
                        initialValue={0}
                        rules={[{ required: true, message: "Revenue required"}]}
                    >
                        <InputNumber
                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            style={{width:"100%"}}
                            min={0}
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label = {<b>How many customer interviews did you have last week?</b>}
                        name='lastInterviews'
                        initialValue={0}
                        rules={[{ required: true, message: "Interviews required" }]}
                    >
                        <InputNumber
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            style={{width:"100%"}}
                            min={0}
                        />
                    </Form.Item>
                </Col> */}
                <Col span={24}>
                    <Form.Item
                        label = {<b>Did you attend last week's Accountability Group Meeting?</b>}
                        name='accountabilityMeetingAttendance'
                        initialValue={false}
                        valuePropName="checked"
                    >
                        <Switch
                            checkedChildren="Yes"
                            unCheckedChildren="No"
                        />
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label = {<b>How many goals you set for the week were completed?</b>}
                    >
                        {goalsUpdate && lastGoals.map((goal, i)=> 
                            <Row style={{paddingBottom:"10px"}} key={`checkbox-${i}`}>
                                <Checkbox
                                    defaultChecked={goal.checked}
                                    onChange={(e)=>{handleGoalCheck(e.target.checked, i)}}
                                >
                                    {goal.summary}
                                </Checkbox>
                            </Row>
                        )}
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        label = {<b>Set your goals for next week</b>}
                        required
                    >
                        <Form.List
                            name="newGoals"
                            rules={[{ validator: async (_, goals) => {
                                        if (!goals || goals.length < 1)
                                            return Promise.reject(new Error('Set at least 1 goal'));
                                      } 
                                    }]}
                        >
                            {(fields, { add, remove }, { errors }) => (
                            <>
                                {fields.map((field, index) => (
                                <Form.Item
                                    key={field.key}
                                >
                                    <Form.Item
                                    {...field}
                                    rules={[
                                        {
                                        required: true,
                                        whitespace: true,
                                        message: "Please fill the goal",
                                        },
                                    ]}
                                    noStyle
                                    >
                                        <Input placeholder={`Goal ${index+1}`} style={{ width: '94%' }} />
                                    </Form.Item>
                                    <MinusCircleOutlined
                                        style={{marginLeft: "10px"}}
                                        onClick={() => remove(field.name)}
                                    />
                                </Form.Item>
                                ))}
                                <Form.Item>
                                <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '40%' }}
                                    icon={<PlusOutlined />}
                                >
                                    Add goal
                                </Button>
                                <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                            )}
                        </Form.List>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item style={{textAlign: "center"}}>
                        <Button htmlType = 'submit' type = 'primary' size = 'large'>Submit</Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
      </Spin>
      </Modal>
   )
}

export default ProgressModal