import React, { useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { EditOutlined, CheckOutlined, TeamOutlined } from '@ant-design/icons'
import { Container, StyledSider, StyledMenu, Content } from './styles'
import Evaluate from './evaluate'
import AGM from './accountability'
import Edit from './edit'

function Persistence() {
    const [menuOption, setMenuOption] = useState('evaluate')
    const { selectedProgram } = useContext(UserContext)

    const handleMenuClick = (e) => {
        setMenuOption(e.key)
    }

    return(
        <Container>
            <StyledSider 
                theme='light'
                width={200}
                breakpoint="md"
                collapsedWidth="0"
                zeroWidthTriggerStyle={{border: "1px solid lightgray"}}
                >
                <StyledMenu
                    theme = 'light'
                    onClick = { handleMenuClick }
                    mode = "inline"
                    defaultSelectedKeys={['evaluate']}
                >
                        <StyledMenu.Item key='evaluate' icon = { <CheckOutlined /> }>
                            Evaluate 
                        </StyledMenu.Item>
                        <StyledMenu.Item key='accountability' icon = { <TeamOutlined /> }>
                            AGM
                        </StyledMenu.Item>
                        <StyledMenu.Item key='edit' icon = { <EditOutlined /> }>
                            Edit
                        </StyledMenu.Item>
                </StyledMenu>
            </StyledSider>
            <Content>
                {
                    menuOption === 'evaluate' ? <Evaluate selectedProgram={selectedProgram}/> : 
                    menuOption === 'edit' ? <Edit selectedProgram={selectedProgram}/> :
                    <AGM selectedProgram={selectedProgram}/> 
                }
            </Content>
        </Container>
    )
}

export default Persistence