import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow, FormCol, ButtonCol } from './styles'
import { Table, Space, Button, Popconfirm, Form, Input, Select, message, Tooltip, InputNumber } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';

function OrderInput({record, selectedProgram}) {
    const [ loading, setLoading ] = useState(false);
    const setQuestionOrder = firebase.functions().httpsCallable('setQuestionOrder');

    const handleOrderChange = async (order) => {
        try {
            setLoading(true);
            await setQuestionOrder({questionId: record.id, order: order, programName: selectedProgram});
            setLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false);
        }
    }

    return (
        record.enabled ? 
            <InputNumber min={1} max={50} defaultValue={record.order} onChange={handleOrderChange} />
        :
            <InputNumber min={1} max={50} defaultValue={record.order} onChange={handleOrderChange} disabled={true} />
    )
}

function DeleteButton({record, selectedProgram}) {
    const [ loading, setLoading ] = useState(false);
    const deleteQuestion = firebase.functions().httpsCallable('deleteQuestion');

    const handleDeletion = async () => {
        try {
            setLoading(true);
            await deleteQuestion({questionId: record.id, programName: selectedProgram});
            message.success("Question deleted successfully!")
            setLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false);
        }
    }

    return (
        <Popconfirm
            title="Are you sure?"
            onConfirm={()=>{handleDeletion()}}
            okType="danger"
            okText="Yes"
            cancelText="No"
        >
            <Button loading={loading} danger>Delete</Button>
        </Popconfirm>
    )
}

function Config({selectedProgram}) {
    const [ listLoading, setListLoading ] = useState(true);
    const [ categoryLoading, setCategoryLoading ] = useState(true);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ buttonLoading, setButtonLoading ] = useState(false);
    const [ active, setActive ] = useState(false);
    const [ categories, setCategories ] = useState([]);
    const [ data, setData ] = useState([]);
    const [ form ] = Form.useForm()

    const getCategories = firebase.functions().httpsCallable('getCategories');
    const addQuestion = firebase.functions().httpsCallable('addQuestion');
    const startPresentations = firebase.functions().httpsCallable('startPresentations');
    const processEvaluations = firebase.functions().httpsCallable('processEvaluations');
    const closePresentations = firebase.functions().httpsCallable('closePresentations');
    const deletePresentations = firebase.functions().httpsCallable('deletePresentations');
    const db = firebase.firestore();

    useEffect(()=>{
        const unsub = db.collection('programs').doc(selectedProgram).collection('presentations').onSnapshot(checkActive);
        return unsub;
    }, [])

    const checkActive = (snapshot) => {
        if (!snapshot.empty) {
            const presData = snapshot.docs[snapshot.size - 1].data();
            setActive(presData.active || false);
        }
        else setActive(false);
    }

    useEffect(()=>{
        const unsub = db.collection('programs').doc(selectedProgram).collection('presentationsQuestions').orderBy('enabled', 'desc').orderBy('order').orderBy('question').onSnapshot(loadQuestions);
        return unsub;
    }, [])

    const loadQuestions = (snapshot) => {
        let newData = snapshot.docs.map(doc => {
            const data = doc.data();
            data.key = doc.id;
            data.id = doc.id;
            return data;
        });
        setData(newData);
        setListLoading(false);
    }

    useEffect(()=>{
        let mounted = true;
        loadCategories().then(data => {if (mounted) setCategories(data)});
        return () => {mounted = false}
    }, [])

    const loadCategories = async () => {
        try {
            setCategoryLoading(true);
            const data = (await getCategories({programName: selectedProgram})).data;
            setCategoryLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setCategoryLoading(false)
            return []
        }
    }

    const handleAdd = async (data) => {
        try {
            setFormLoading(true);
            await addQuestion({...data, programName: selectedProgram});
            form.resetFields();
            message.success("Question added successfully!")
            setFormLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setFormLoading(false)
        }
    }

    const handleStartPresentations = async () => {
        try {
            setButtonLoading(true);
            await startPresentations({programName: selectedProgram});
            message.success("Presentations started!")
            setButtonLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setButtonLoading(false)
        }
    }
    
    const handleProcessEvaluations = async () => {
        try {
            setButtonLoading(true);
            await processEvaluations({programName: selectedProgram});
            message.success("Evaluations processed!")
            setButtonLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setButtonLoading(false)
        }
    }

    const handleClosePresentations = async () => {
        try {
            setButtonLoading(true);
            await closePresentations({programName: selectedProgram});
            message.success("Presentations closed!")
            setButtonLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setButtonLoading(false)
        }
    }

    const handleDeletePresentations = async () => {
        try {
            setButtonLoading(true);
            await deletePresentations({programName: selectedProgram});
            message.success("Presentations deleted!")
            setButtonLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setButtonLoading(false)
        }
    }

    const columns = [
        {
            title: 'Category',
            dataIndex: 'category',
            key: 'category',
            width: "30%",
            render: (text, record) => (
            <Space style={{textTransform: 'capitalize'}}>{record.category.replace("-", " ")}</Space>
            )
        },
        {
            title: 'Question',
            dataIndex: 'question',
            key: 'question',
            width: "50%",
            render: (text, record) => {
                if (!record.enabled) {
                    return  <Tooltip title="This question has been used in a previous presentation evaluation, it won't show up in the evaluation form. Please keep at least one of each category as a historic reminder.">
                                <Space style={{textDecoration: 'line-through'}}>{record.question}</Space>
                            </Tooltip>
                }
                else {
                    return record.question
                }
            }
        },
        {
            title: 'Order',
            key: 'order',
            render: (text, record) => (
              <Space size="middle">
                  <OrderInput record={record} selectedProgram={selectedProgram}/>
              </Space>
            ),
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                <DeleteButton record={record} selectedProgram={selectedProgram}/>
              </Space>
            ),
        },
    ]

    return(
        <WhiteContainer>
            <FullRow separator='true'>
              <h2>Presentations Configuration</h2>
            </FullRow>
            <h3>Presentations Status</h3>
            <FullRow separator='true' gutter={16}>
                { !active ? 
                <ButtonCol xs={24} sm={12} md={12} lg={8} xl={4}>
                    <Button type="primary" loading={buttonLoading} onClick={handleStartPresentations}>Start Presentations</Button>
                </ButtonCol>
                : <>
                <ButtonCol xs={24} sm={12} md={12} lg={8} xl={4}>
                    <Tooltip
                        placement='top'
                        title = "Computes current participants scores without closing the presentations. Users will still be able to evaluate participants!"
                    >
                        <Button type="primary" loading={buttonLoading} onClick={handleProcessEvaluations}>Process Evaluations</Button>
                    </Tooltip>
                </ButtonCol>
                <ButtonCol xs={24} sm={12} md={12} lg={8} xl={4}>
                    <Popconfirm
                        title="Are you sure? Current evaluations will be processed and current categories cannot be used on future presentations"
                        onConfirm={handleClosePresentations}
                        okType="danger"
                        okText="Yes"
                        cancelText="No"
                        overlayStyle={{maxWidth: "400px"}}
                    >
                        <Button type="ghost" loading={buttonLoading}>Close Presentations</Button>
                    </Popconfirm>
                </ButtonCol>
                <ButtonCol xs={24} sm={12} md={12} lg={8} xl={4}>
                    <Popconfirm
                        title="Are you sure? This will remove all the current evaluations"
                        onConfirm={handleDeletePresentations}
                        okType="danger"
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger loading={buttonLoading}>Delete Presentations</Button>
                    </Popconfirm>
                </ButtonCol>
                </> 
                }
            </FullRow>
            <h3>Add Question</h3>
            <Form
                form={form}
                layout="inline"
                name="addQuestion"
                onFinish={handleAdd}
                style={{marginBottom: "25px"}}
            >   
                <FullRow>
                    <FormCol sm={4} xs={24} style={{marginBottom: "10px"}}>
                        <Form.Item
                            name="category" 
                            rules={[{ required: true, message: 'Select a category' }]}
                        >
                            <Select placeholder="Select a category" loading={categoryLoading} style={{textTransform: 'capitalize'}}>
                                {categories.map((cat)=>
                                    <Select.Option disabled={!cat.enabled} value={cat.category} key={"cat-"+cat.category} style={{textTransform: 'capitalize'}}>
                                        {cat.category.replace("-", " ")}
                                    </Select.Option>
                                )}
                            </Select>
                        </Form.Item>
                    </FormCol>
                    <FormCol sm={16} xs={24} style={{marginBottom: "10px"}}>
                        <Form.Item
                            name="question"
                            rules={[{ required: true, message: 'Enter the question' }]}
                        >
                            <Input prefix={<QuestionCircleOutlined/>} placeholder="Question"/>
                        </Form.Item>
                    </FormCol>
                    <FormCol sm={4} xs={24} style={{marginBottom: "10px"}}>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={formLoading}>
                                Submit
                            </Button>
                        </Form.Item>
                    </FormCol>
                </FullRow>
            </Form>
            <h3>Question List</h3>
            <Table
                style={{overflowX: "auto", overflowY: 'hidden'}}
                bordered
                pagination={false}
                loading={listLoading} 
                columns={columns}
                dataSource={data}
            />
        </WhiteContainer>
    )
}

export default Config