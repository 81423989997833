import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow } from './styles'
import { Space, Avatar, Table, Checkbox, message, Tooltip } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';

const baseCol = [
    {
      title: 'Participant',
      dataIndex: 'participant',
      key: 'participant',
      render: (text, record) => (
        <Space size="middle">
            { record.photoURL ?
                <Avatar size={32} src={record.photoURL} style={{marginRight: "10px"}}/>
                : <Avatar size={32} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/> }
            {record.displayName}
        </Space>
      ),
    }        
]

function AGM({selectedProgram}) {
    const [ loading, setLoading ] = useState(true);
    const [ columns, setColumns ] = useState(baseCol);
    const [ data, setData ] = useState([]);

    const getHistoricAccountability = firebase.functions().httpsCallable('getHistoricAccountability');

    useEffect(() => {
        let mounted = true;
        loadData().then(data => {
            if (mounted) {
                setColumns(data.columns);
                setData(data.rows);
            }
        });
        return () => {mounted = false}
    },[])

    const loadData = async () => {
        try {
            setLoading(true);
            const res = (await getHistoricAccountability({programName: selectedProgram})).data;

            const columns = Array.from({length: res.currentWeek}, (_, i) => {
                return {
                    title: <Tooltip title={(i+1 === res.currentWeek ? "Submitted by the end of the week in the User Progress Form" : "")}>
                             {'Accountability W' + (i+1)}
                           </Tooltip>,
                    dataIndex: 'agm' + (i+1),
                    key: 'agm' + (i+1),
                    render: (text, record) => (
                        <div style={{textAlign:"center"}}>
                        <Checkbox
                            defaultChecked={record.historic[(i+1)] ? true : false}
                            disabled
                        />
                        </div>
                    )
                }
            })

            const data = res.participantsAccountability.map((record) => {
                record.key = record.uid;
                return record
            })
            setLoading(false);
            return {rows: data, columns: [...baseCol, ...columns]};
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false)
            return {rows: [], columns: baseCol}
        }
    }

    return(
        <WhiteContainer>
            <FullRow separator='true'>
              <h2>Accountability Group Meetings</h2>
            </FullRow>
            <Table
                style={{overflowX: "auto", overflowY: 'hidden'}}
                bordered
                pagination={false}
                loading={loading} 
                columns={columns}
                dataSource={data}
                size="small"
            />
        </WhiteContainer>
    )
}

export default AGM