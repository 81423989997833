import React, {useContext} from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { UserContext } from './contexts/UserContext'
import Login from './views/Login'
import Reset from './views/ResetPassword'
import Signup from './views/Signup'
import Dashboard from './views/Dashboard'
import Resources from './views/Resources'
import Community from './views/Community'
import Leaderboard from './views/Leaderboard'
import KarmaOverview from './views/Karma'
import Persistence from './views/Persistence'
import UserSettings from './views/UserSettings'
import Presentations from './views/Presentations'
import Programs from './views/Programs'
import NavBar from './Components/NavBar'
import Testing from './views/Testing'


const PublicRoutes = (
    <>
        <Route path ='/login' exact component = {Login} />
        <Route path ='/reset' exact component = {Reset} />
        <Route path = '/signup' exact component = {Signup} />
        <Route path = '/' exact><Redirect to ='/login' /></Route>
    </>
)

const PrivateRoutes = (userInfo) => (
    <>
        <Route path ='/dashboard/:refresh?' exact component = {Dashboard} />
        <Route path = '/resources' exact component = {Resources} />
        <Route path = '/community' exact component = {Community} />
        <Route path = '/leaderboard' exact component = {Leaderboard} />
        <Route path = '/presentations' exact component = {Presentations} />
        <Route path = '/settings' exact component = {UserSettings} />
        <Route path = '/programs' exact component = {Programs} />
        {/* <Route path = '/testing' exact component = {Testing} /> */}
        <Route path = '/' exact><Redirect to ='/dashboard' /></Route>
        <Route path = '/login' exact><Redirect to ='/dashboard' /></Route>
        <Route path = '/signup' exact><Redirect to ='/dashboard' /></Route>
        <Route path = '/reset' exact><Redirect to ='/dashboard' /></Route>
        {(userInfo?.claims?.participant || userInfo?.claims?.graduate) && 
            <Route path = '/karma' exact component = {KarmaOverview} />
        }
        {userInfo?.claims?.admin &&
            <Route path = '/persistence' exact component = {Persistence} />
        }

    </>
)

function AppRoutes() {
    const { userAuth, userInfo } = useContext(UserContext)

    return(<>
        <Router>
            <NavBar>
            <Switch>
                {
                    userAuth 
                    ? PrivateRoutes(userInfo)
                    : PublicRoutes
                }
            </Switch>
            </NavBar>
        </Router>
    </>)

}

export default AppRoutes