import { notification } from 'antd';
import firebase from 'firebase/app';
import 'firebase/functions'

export default class ProgramsService {
    
    static async getAllPrograms() {
        try {
            const programs = await firebase.functions().httpsCallable('getAllPrograms')()
            return programs.data
        }
        catch(error) {
            notification.error({
                message: 'There was a problem fetching the programs',
                description: ''
            })
        }
    }

    static async getActivePrograms() {
        try {
            const activePrograms = await firebase.functions().httpsCallable('getActivePrograms')()
            return activePrograms.data
        }
        catch(error) {
            notification.error({
                messsage: 'There was a problem fetching the active programs',
                description: ''
            })
        }
    }

    static async createNewProgram(programData) {
        try {
            await firebase.functions().httpsCallable('createNewProgram')(programData)
            return true
        }
        catch(error) {
            notification.error({
                message: 'There was a problem creating the new program',
                // description: 'Please refresh the screen and try again'
                description: error.message
            })
            return false
        }
    }

    static async graduateProgram(programName) {
        try {
            await firebase.functions().httpsCallable('graduateParticipants')({programName})
            return true
        }
        catch(error) {
            notification.error({
                message: 'There was a problem graduating the cohort',
                // description: 'Please refresh the screen and try again'
                description: error.message
            })
            return false
        }
    }
}