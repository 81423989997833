import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import firebase from 'firebase/app';

const env = process.env.REACT_APP_ENV || "development";

var firebaseConfig = {
  apiKey: "AIzaSyBkbLSfDC509nIJ5sc2Hc2KAjeS9ZrRtWM",
  authDomain: "accelerator-platform-1909.firebaseapp.com",
  databaseURL: "https://accelerator-platform-1909.firebaseio.com",
  projectId: "accelerator-platform-1909",
  storageBucket: "accelerator-platform-1909.appspot.com",
  messagingSenderId: "320842759139",
  appId: "1:320842759139:web:5a155adcd24c7b436ffed6",
  measurementId: "G-H1XWH1NXQP"
};

if (env === "development") {
  firebaseConfig = {
    apiKey: "AIzaSyBOCyvZkLljlFm0i-mj5WdyVXAJAm3SsV4",
    authDomain: "accelerator-dev.firebaseapp.com",
    projectId: "accelerator-dev",
    storageBucket: "accelerator-dev.appspot.com",
    messagingSenderId: "149836493657",
    appId: "1:149836493657:web:5bb6f673dbd2dece0b9248",
    measurementId: "G-PLQ7Z2DP4B"
  };
}

firebase.initializeApp(firebaseConfig);

//Initialize the emulator on localhost
if(window.location.host === 'localhost:3000'){
  firebase.functions().useEmulator("localhost", 5001);
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

