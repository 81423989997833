import React from 'react'
import { ChartContainer } from '../styles'
import { Line } from 'react-chartjs-2'

function KarmaChart({xData, karma}) {

    const data = {
      labels: xData,
      datasets: [
        {
          label: 'Received Karma Points',
          data: karma,
          backgroundColor: 'rgba(102, 51, 153, 0.5)',
          borderColor: 'rgb(102, 51, 153)',
        }
      ],
    }
    
    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [ {
          scaleLabel: {
            display: true,
            labelString: 'Week'
          } 
        } ],
        yAxes: [{
          ticks: {
              beginAtZero: true
          }
        }]
      }
    }

    return(
          <ChartContainer className="chart-container">
              <Line data={data} options={options}/>
          </ChartContainer>
    )
}

export default KarmaChart