import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { UserContext } from '../../contexts/UserContext';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import { Layout, Menu, Avatar, Popover, Drawer, Select } from 'antd';
import { UserOutlined, HomeOutlined, UserAddOutlined, LogoutOutlined, TeamOutlined, CalendarOutlined, AimOutlined, CarryOutOutlined, TrophyOutlined, MenuOutlined, CloseOutlined, FundProjectionScreenOutlined, AppstoreOutlined } from '@ant-design/icons';
import { LogoDiv, LogoImg, NameDiv, WideMenu, MenuButton } from './styles';
import ProgressModal from './progressModal';
import ProgramsService from '../../services/ProgramsService';

function NavBar({children}) {
    const { userAuth, userInfo, selectedProgram, setSelectedProgram } = useContext(UserContext);
    const [ openMenu, setOpenMenu ] = useState(false);
    const [ progressModal, setProgressModal ] = useState(false);
    const [ programsLoading, setProgramsLoading ] = useState(true);
    const [ menuDrawer, setMenuDrawer ] = useState(false);
    const [ programs, setPrograms ] = useState([]);
    const history = useHistory();
    
    // Instantiate firestore
    const db = firebase.firestore();

    useEffect(() => {
        let mounted = true
        if (userInfo?.claims?.admin) {
            getAllPrograms().then((list) => {
                if (mounted) {
                    setPrograms(list)
                    setProgramsLoading(false)
                }
            })
        }

        return () => mounted = false
    }, [userInfo])

    useEffect(()=>{
        if (userInfo?.uid) {
            const unsub = db.collection('profiles').doc(userInfo.uid).onSnapshot(profileChange);
            return unsub;
        }
    }, [userInfo])

    const profileChange = (snapshot) => {
        const data = snapshot.data();
        if (data?.timeToTrackProgress) setProgressModal(true);
        else setProgressModal(false);
    };

    const handleLogout = () => {
        setMenuDrawer(false);
        firebase.auth().signOut().then(async ()=>{
            // Sleep for a milisecond
            await new Promise(r => setTimeout(r, 1));
            history.push('/');
          })
          .catch(console.log);
    };

    const getAllPrograms = async () => {
        setProgramsLoading(true)
        const programs = await ProgramsService.getAllPrograms()
        return programs
    }

    const MenuOverlay = (
        <Menu style={{border: "none"}} selectable={false}>
            <Menu.Item key="drop1" icon={<UserOutlined />} onClick={()=>{history.push("/settings")}}>
            Profile Settings
            </Menu.Item>
            <Menu.Item key="drop2" icon={<LogoutOutlined />} onClick={handleLogout}>
            Log Out
            </Menu.Item>
        </Menu>
      )

    return(<>
            <Layout>
                <Layout.Header
                    style={{backgroundColor: "#2b2b2b", boxShadow: "2px 0 10px 4px rgb(0 0 0 / 25%)"}}
                >   
                    <MenuButton onClick={()=>{setMenuDrawer(true)}}>
                        <MenuOutlined/>
                    </MenuButton>

                    <LogoDiv className="logo">
                        <LogoImg alt='logo' src={require('../../assets/1909-white.png').default}/>
                    </LogoDiv>
                
                    { userAuth && 
                        <>  <Popover
                                content={MenuOverlay}
                                trigger="click"
                                visible={openMenu}
                                onVisibleChange={setOpenMenu}
                                align={{ offset: [15, -19] }}
                                >

                                <NameDiv>
                                    { userInfo.photoURL ? <Avatar size = { 45 } src={userInfo.photoURL} />
                                    : <Avatar size = { 45 } icon = { <UserOutlined /> }/> }
                                    <h3>{userInfo.displayName}</h3>
                                </NameDiv> 
                            </Popover>
                        </>
                    }

                    <WideMenu theme="dark" mode="horizontal" style={{borderRadius: "5px", backgroundColor: "#2b2b2b"}} selectable={false}>
                        { userAuth && userInfo ? <>
                        <Menu.Item key="1" icon={<HomeOutlined />} onClick={()=>{history.push("/dashboard")}}>
                            Dashboard
                        </Menu.Item>
                        <Menu.Item key="2" icon={<TeamOutlined />} onClick={()=>{history.push("/community")}}>
                            Community
                        </Menu.Item>
                        <Menu.Item key="3" icon={<CalendarOutlined />} onClick={()=>{history.push("/resources")}}>
                            Resources
                        </Menu.Item>
                        {(userInfo?.claims?.participant || userInfo?.claims?.graduate) &&
                            <> 
                                <Menu.Item key="6" icon={<AimOutlined />} onClick={()=>{history.push("/karma")}}>
                                    Karma Overview
                                </Menu.Item>
                                <Menu.Item key="4" icon={<TrophyOutlined />} onClick={()=>{history.push("/leaderboard")}}>
                                    Leaderboard
                                </Menu.Item>
                                <Menu.Item key="5" icon={<FundProjectionScreenOutlined />} onClick={()=>{history.push("/presentations")}}>
                                    Presentations
                                </Menu.Item>
                            </>
                        }
                        {userInfo.claims && userInfo.claims?.admin && 
                            <>
                                <Menu.Item key="7" icon={<CarryOutOutlined />} onClick={()=>{history.push("/persistence")}}>
                                    Persistence
                                </Menu.Item>
                                <Menu.Item key="4" icon={<TrophyOutlined />} onClick={()=>{history.push("/leaderboard")}}>
                                    Leaderboard
                                </Menu.Item>
                                <Menu.Item key="5" icon={<FundProjectionScreenOutlined />} onClick={()=>{history.push("/presentations")}}>
                                    Presentations
                                </Menu.Item>
                                <Menu.Item key="6" icon={<AppstoreOutlined />} onClick={()=>{history.push("/programs")}}>
                                    Programs
                                </Menu.Item>
                                <Menu.Item >
                                    <Select defaultValue={selectedProgram} placeholder="Select a Program" loading={programsLoading} onSelect={setSelectedProgram}>
                                        {programs.map((program)=>
                                            <Select.Option value={program} key={"program-"+program}>
                                                {program}
                                            </Select.Option>
                                        )}
                                    </Select>
                                </Menu.Item>
                            </>
                        }
                        </>
                        : <>
                        <Menu.Item key="1" icon={<HomeOutlined />} onClick={()=> {history.push("/")}}>
                        Home
                        </Menu.Item>
                        <Menu.Item key="2" icon={<UserOutlined />} onClick={()=> {history.push("/login")}}>
                        Log In
                        </Menu.Item>
                        <Menu.Item key="3" icon={<UserAddOutlined />} onClick={()=> {history.push("/signup")}}>
                        Sign Up
                        </Menu.Item>
                        </>}
                    </WideMenu>
                    
                    <Drawer
                        title={<LogoImg alt='logo' src={require('../../assets/1909-white.png').default}/>}
                        placement="left"
                        onClose={()=>{setMenuDrawer(false)}}
                        visible={menuDrawer}
                        drawerStyle={{backgroundColor: "#2b2b2b", color: "lightgray"}}
                        headerStyle={{backgroundColor: "#2b2b2b", color: "lightgray", border: "none"}}
                        closeIcon={<CloseOutlined style={{color: "lightgray"}} />}
                    >
                    <Menu theme="dark" mode="vertical" style={{borderRadius: "5px", backgroundColor: "#2b2b2b"}} selectable={false}>
                        { userAuth ? <>
                        <Menu.Item key="1" icon={<HomeOutlined />} onClick={()=>{history.push("/dashboard"); setMenuDrawer(false)}}>
                        Dashboard
                        </Menu.Item>
                        <Menu.Item key="2" icon={<TeamOutlined />} onClick={()=>{history.push("/community"); setMenuDrawer(false)}}>
                        Community
                        </Menu.Item>
                        <Menu.Item key="3" icon={<CalendarOutlined />} onClick={()=>{history.push("/resources"); setMenuDrawer(false)}}>
                        Resources
                        </Menu.Item>
                        <Menu.Item key="4" icon={<TrophyOutlined />} onClick={()=>{history.push("/leaderboard"); setMenuDrawer(false)}}>
                        Leaderboard
                        </Menu.Item>
                        <Menu.Item key="5" icon={<FundProjectionScreenOutlined />} onClick={()=>{history.push("/presentations")}}>
                        Presentations
                        </Menu.Item>
                        {(userInfo?.claims?.participant || userInfo?.claims?.graduate) && 
                            <Menu.Item key="6" icon={<AimOutlined />} onClick={()=>{history.push("/karma"); setMenuDrawer(false)}}>
                            Karma Overview
                            </Menu.Item>
                        }
                        {userInfo?.claims?.admin && 
                            <Menu.Item key="7" icon={<CarryOutOutlined />} onClick={()=>{history.push("/persistence"); setMenuDrawer(false)}}>
                            Persistence
                            </Menu.Item>
                        }
                        <Menu.Item key="8" icon={<UserOutlined />} onClick={()=>{history.push("/settings"); setMenuDrawer(false)}}>
                        Profile Settings
                        </Menu.Item>
                        <Menu.Item key="9" icon={<LogoutOutlined />} onClick={handleLogout}>
                        Log Out
                        </Menu.Item>
                        </>
                        : <>
                        <Menu.Item key="1" icon={<HomeOutlined />} onClick={()=> {history.push("/"); setMenuDrawer(false)}}>
                        Home
                        </Menu.Item>
                        <Menu.Item key="2" icon={<UserOutlined />} onClick={()=> {history.push("/login"); setMenuDrawer(false)}}>
                        Log In
                        </Menu.Item>
                        <Menu.Item key="3" icon={<UserAddOutlined />} onClick={()=> {history.push("/signup"); setMenuDrawer(false)}}>
                        Sign Up
                        </Menu.Item>
                        </>}
                    </Menu>
                    
                    </Drawer>

                </Layout.Header>
                <Layout>
                    {children}
                </Layout>
            </Layout>
            { userAuth && userInfo.claims?.participant && <ProgressModal visible={progressModal}/> }
    </>
    )
}

export default NavBar