import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow } from './styles'
import { Table, Space, Button, Tooltip, Popconfirm, Form, Input, message, InputNumber, Radio } from 'antd'
import { EditOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore'

function StatusButton({record, selectedProgram}) {
    const [ loading, setLoading ] = useState(false);
    const switchEvaluationStatus = firebase.functions().httpsCallable('switchEvaluationStatus');

    const handleToggleStatus = async () => {
        try {
            setLoading(true);
            await switchEvaluationStatus({evaluationId: record.id, enabled: !record.enabled, programName: selectedProgram});
            if (record.enabled) message.success("Evaluation disabled successfully!");
            else message.success("Evaluation enabled successfully!");
            setLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false);
        }
    }

    return (
        <Button
            type={`${record.enabled ? 'dashed' : 'default'}`}
            onClick={()=>{handleToggleStatus()}}
            loading={loading}
        >
            {record.enabled ? "Disable" : "Enable"}
        </Button>
    )
}

function DeleteButton({record, selectedProgram}) {
    const [ loading, setLoading ] = useState(false);
    const deleteEvaluation = firebase.functions().httpsCallable('deleteEvaluation');

    const handleDeletion = async () => {
        try {
            setLoading(true);
            await deleteEvaluation({evaluationId: record.id, programName: selectedProgram});
            message.success("Evaluation deleted successfully!")
            setLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false);
        }
    }

    return (
        !record.used ?
        <Popconfirm
            title="Are you sure?"
            onConfirm={()=>{handleDeletion()}}
            okType="danger"
            okText="Yes"
            cancelText="No"
        >
            <Button loading={loading} danger>Delete</Button>
        </Popconfirm>
        :   
        <Tooltip placement="right" title="If this evaluation has been used before, it can't be deleted for consistency purposes. But it can be disabled if you don't want to see it.">
                <Button danger disabled>Delete</Button>
        </Tooltip>
    )
}

function Edit({selectedProgram}) {
    const [ listLoading, setListLoading ] = useState(true);
    const [ formLoading, setFormLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ form ] = Form.useForm();
    const [ type, setType ] = useState(1);

    const createEvaluation = firebase.functions().httpsCallable('createEvaluation');
    const db = firebase.firestore();

    useEffect(()=>{
        const unsub = db.collection('programs').doc(selectedProgram).collection('persistenceEvaluations').orderBy('description').onSnapshot(loadEvaluations);
        return unsub;
    }, [])

    const loadEvaluations = (snapshot) => {
        let newData = snapshot.docs.map(doc => {
            const data = doc.data();
            data.key = doc.id;
            data.id = doc.id;
            return data;
        });
        setData(newData);
        setListLoading(false);
    }

    const handleAdd = async (data) => {
        try {
            setFormLoading(true);
            await createEvaluation({...data, programName: selectedProgram});
            form.resetFields();
            message.success("Evaluation added successfully!")
            setFormLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setFormLoading(false)
        }
    }

    const handleType = e => {
        setType(e.target.value);
    };
    
    const columns = [
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          width: "50%"
        },
        {
          title: 'Points',
          dataIndex: 'points',
          key: 'points',
          width: "10%"
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
              <Space size="middle">
                <StatusButton record={record} selectedProgram={selectedProgram}/>
                <DeleteButton record={record} selectedProgram={selectedProgram}/>
              </Space>
            ),
          },
    ]

    return(
        <WhiteContainer>
            <FullRow separator='true'>
              <h2>Edit Evaluations</h2>
            </FullRow>
            <h3>Add Evaluation</h3>
            <Form
                form={form}
                layout="inline"
                name="addEvaluation"
                onFinish={handleAdd}
                style={{marginBottom: "25px"}}
            >   
                <Form.Item
                    name="type"
                    rules={[{ required: true, message: 'Enter type' }]}
                >
                    <Radio.Group onChange={handleType} value={type}>
                        <Radio value={"checkbox"}>Checkbox</Radio>
                        <Radio value={"number"}>Number</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="description"
                    rules={[{ required: true, message: 'Enter description' }]}
                >
                    <Input prefix={<EditOutlined/>} placeholder="Description"/>
                </Form.Item>
                <Form.Item
                    name="points"
                    rules={[{ required: true, message: 'Enter points' }]}
                >
                    <InputNumber placeholder="Points"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={formLoading}>
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <h3>Evaluation List</h3>
            <Table
                style={{overflowX: "auto", overflowY: 'hidden'}}
                bordered
                pagination={false}
                loading={listLoading} 
                columns={columns}
                dataSource={data}
            />
        </WhiteContainer>
    )
}

export default Edit