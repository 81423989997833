import React, { useState } from 'react'
import { Row, Col, Form, Input, Button, Spin, message, Tooltip } from 'antd'
import { EditOutlined, GlobalOutlined, LoadingOutlined} from '@ant-design/icons'
import { WhiteContainer } from './styles'

function ResourceForm({onFinish}) {
    const [ form ] = Form.useForm();
    const [ loader, setLoader ] = useState(false);

    const handleSubmit = async (data) => {
        setLoader(true);
        try {
            await onFinish(data);
            message.success("Resource added successfully");
            form.resetFields();
        }
        catch (error) {
            message.error(error.message);
        }
        setLoader(false);
    }

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <Spin indicator={spinnerIcon} spinning={loader}>
            <WhiteContainer>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish ={ handleSubmit }
                        style={{width: "100%"}}
                        >
                        <Row gutter={16}>
                            <Col span={24}>
                                
                                <Form.Item
                                    label = {<Tooltip title="Make sure the resource link includes the http protocol!">
                                                <b>Link</b>
                                            </Tooltip>}
                                    name='link'
                                    rules={[{ required: true }]}
                                >
                                    <Input type="url" prefix={<GlobalOutlined />} placeholder="Resource URL"/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label = {<b>Name</b>}
                                    name='name'
                                    rules={[{ required: true }]}
                                >
                                    <Input prefix={<EditOutlined />} placeholder="Resource name"/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item style={{textAlign: "center"}}>
                                    <Button htmlType = 'submit' type = 'primary' size = 'large'>Submit</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
            </WhiteContainer>
        </Spin>
    )
}

export default ResourceForm
