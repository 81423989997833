import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  .App {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }

  body>#root>* {
    min-height: 100vh;
  }

  body {
    font-family: 'Lato', sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    color: black;
  }

  // Navbar background
  .ant-layout-header {
    background: rgb(43, 43, 43) !important;
  }

  .ant-menu.ant-menu-dark, .ant-menu-dark .ant-menu-sub, .ant-menu.ant-menu-dark .ant-menu-sub {
    background: rgb(43, 43, 43) !important;
  }

  // Navbar items
  .ant-layout-header .ant-menu-item {
    background: rgb(43, 43, 43);
    color: rgba(255, 255, 255, 0.65) !important;
  }

  .ant-layout-header .ant-menu-item:hover {
    background: #1890ff !important;
    color: rgba(255, 255, 255, 0.65) !important;
  }

  // App background
  .ant-layout {
    background: #f0f2f5; 
  }

  // Primary buttons 
  .ant-btn-primary {
    color: white;
    background: #1890ff;
    border-color: #1890ff;
  }

  .ant-btn-primary:hover {
    color: white;
  }

  // Delete buttons
  .ant-btn-dangerous {
    color: #ff4d4f;
    background: #fff;
    border-color: #ff4d4f;
  }

  // Enable buttons 
  .ant-btn-default {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;
  }

  // Disable buttons
  .ant-btn-dashed {
    color: rgba(0, 0, 0, 0.85);
    background: #fff;
    border-color: #d9d9d9;
    border-style: dashed;
  }

  // Login div container
  .iGoqLr {
    background-color: white;
    border: 1px solid lightgray;
  }

  // Sign up div container 
  .irPkRM {
    background-color: white;
    border: 1px solid lightgray;
  }

  // Participants/admind ashboard div container
  .wqHNg {
    background-color: white;
    border: 1px solid lightgray;
  }

  // Participants dashboard and benchmarks cards
  .ant-card {
    background-color: white;
    border: 1px solid lightgray;
  }

  .ant-card-head-title {
    color: black;
  }

  .ant-card-head-body {
    color: black;
  }

  // Community div container
  .eqksBb {
    background-color: white;
    border: 1px solid lightgray;
  }

  // Community cards
  .laVbUq {
    background-color: white;
    border: 1px solid lightgray;
    color: blue !important;
  }

  .laVbUq h3 {
    color: black;
  }

  .laVbUq p {
    color: gray;
  }

  // Community modals
  .ant-modal-body {
    background: white
  }

  .ant-modal-body p {
    color: rgba(0, 0, 0, 0.85);
  }

  ant-modal-body h1, h3 {
    color: black;
  }

  // Events calendar 
  .ant-picker-calendar {
    background: white;
    border: 1px solid lightgray;
  }

  .ant-picker-calendar .ant-picker-body {
    background: white;
  }

  // Resources list
  .ant-list-bordered {
    border: 1px solid #d9d9d9;
    background-color: white;
  }

  .ant-list-item {
    color: rgba(0, 0, 0, 0.85);
  }

  // Sidebar menu background and user popover menu
  .ant-menu-root.ant-menu-vertical, .ant-menu-root.ant-menu-vertical-left, .ant-menu-root.ant-menu-vertical-right, .ant-menu-root.ant-menu-inline {
    background: white;
  }

  .ant-layout-sider-children {
    background: white;
  }

  // Sidebar menu items
  .ant-menu-item {
    color: black;
  }

  .ant-menu-item:hover {
    color: #1890ff;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #e6f7ff;
    color: #1890ff;
  }

  // Leaderboard div container
  .enqjuQ {
    background-color: white;
    border: 1px solid lightgray;
    color: blue;
  }

  // Leaderboard header
  .ksnNOa {
    color: rgba(0,0,0,0.45);
  }

  .ant-statistic-content {   // Also karma header
    color: rgba(0, 0, 0, 0.85);
  }

  // Leaderboard, admin dashboard, presentations config and admin persistence tables headers
  .ant-table-small .ant-table-thead > tr > th {
    background-color: #fafafa;
  } 

  // Leaderboard, admin dashboard, presentations config and admin persistence tables data
  .ant-table {
    background: white;
    color: black;
  }

  // Presentations results div container
  .jPXKbS {
    background-color: white;
    border: 1px solid lightgray;
  }

  .jPXKbS h2, h3 {
    color: black;
  }

  .jPXKbS .ant-list-item {
    color: rgba(0,0,0,0.85);
  }

  .jPXKbS .ant-list-item-meta-title {
    color: rgba(0,0,0,0.85);
  }

  .ant-list-item-meta-description {
    color: rgba(0, 0, 0, 0.45);
  }

  // Presentations results progress circles


  // Karma div container
  .caKAVT {
    background-color: white;
    border: 1px solid lightgray;
    color: rgba(0, 0, 0, 0.85);
  }

  // Karma header 
  .fCObyd {
    color: rgba(0,0,0,0.45);
  }

  .caKAVT h2 {
    color: black;
  }

  // Karma form 
  .fCObyd .ant-form-item-required {
    color: rgba(0, 0, 0, 0.85);
  }

  // Karma progress lines
  .ant-progress-bg { 
    background-image: linear-gradient(to right, rgb(16, 142, 233), rgb(135, 208, 104)) !important;
  }

  // User popover menu
  .ant-popover-inner-content {
    color: rgba(0, 0, 0, 0.85);
    background: white;
  }

  // User settings div container
  .jDPwSO {
    background-color: white;
    border: 1px solid lightgray;
  }

  // ADMIN
  // Add event or resource div container 
  .xhnvS {
    background-color: white;
    border: 1px solid lightgray;
  }

  .xhnvS h2 {
    color: black;
  }

  // Add form 
  .xhnvS .ant-form-item-required {
    color: rgba(0, 0, 0, 0.85);
  }

  // Presentations configuration div container
  .jPXKbS {
    background-color: white;
    border: 1px solid lightgray;
  }

  .jPXKbS h2, h3 {
    color: black;
  }

  // Evaluate persistence, accountability group meetings results and edit persistence div containers
  .cHgBFa {
    background-color: white;
    border: 1px solid lightgray;
  }

  .cHgBFa h2, h3 {
    color: black;
  }
`;
 
export default GlobalStyle;
