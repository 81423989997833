import styled from 'styled-components'

export const Body = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;


`

export const PageHeader = styled.h1`
    font-family: 'roboto mono', sans-serif;
    margin: 5vh;
    font-size: 25px;
`

export const ProgramContainer = styled.div`
    background-color: white;
    border: 1px solid #f3f3f3;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    display: flex;
    flex-direction: column;
    width: 70vw;
    min-width: 400px;

    .header {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        padding: 20px;
        border-bottom: 1px solid #f3f3f3;   
    }
`

export const ProgramCard = styled.div`
    margin: 20px 30px;
    padding: 15px 30px;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;

`

