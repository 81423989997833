import styled from 'styled-components';
import { Row, Col } from 'antd';

export const KarmaPage = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const Container = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    display: flex;
    flex-direction: column;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
    width: 80%;
`

export const BarPoints = styled.p `
    margin-left: 15px;
`

export const FullRow = styled(Row)
`
    width:100%;
    border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
    margin-bottom: ${props => props.separator ? "30px" : props.mb ? props.mb+"px" : "0px"};
    margin-top: ${props => props.bottom ? "15px" : "0px"};

    padding-bottom: ${props => props.header ? "15px" : "0px"};
`

export const HeaderTitleCol = styled(Col)
`
    align-items: center;
    display: flex;
`

export const FormPointsCol = styled(Col) 
`
    font-weight: bold;
    font-size: large;
    padding-bottom: 20px;
    padding-top: 5px;

    b {
        font-weight: bold;
    }
`

export const StatTitle = styled.div `
    margin-bottom: 4px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    overflow: hidden;

    @media (max-width: 576px) { 
        font-size: 12px;
     }

`

export const ResKarmaCol = styled(Col) 
`
    @media (max-width: 576px) { 
        border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
        margin-bottom: ${props => props.separator ? "30px" : "0px"};
        padding-bottom: ${props => props.separator ? "15px" : "0px"};
    }
`