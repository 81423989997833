import React, { useState, useEffect, useContext } from 'react';
import { UserContext } from '../../../contexts/UserContext';
import { WhiteContainer, FullRow } from './styles';
import { Space, Avatar, Table, Col, Statistic, message, Select } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import firebase from 'firebase/app';
import 'firebase/functions';

function AdminBoard() {
    const { selectedProgram } = useContext(UserContext)

    const [ loading, setLoading ] = useState(false);
    const [ fullData, setFullData ] = useState([]);
    const [ weekData, setWeekData ] = useState([]);
    const [ weeks, setWeeks ] = useState([]);
    const getDashboardData = firebase.functions().httpsCallable('getDashboardData');

    const columns = [
        {
            title: 'Player',
            dataIndex: 'participant',
            key: 'participant',
            render: (text, record, index) => (
            <Space size="middle">
                { record.photoURL ?
                    <Avatar size={32} src={record.photoURL} style={{marginRight: "10px"}}/>
                    : <Avatar size={32} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/> }
                { record.displayName }
            </Space>
            ),
        },
        {
            title: 'Revenue',
            dataIndex: 'revenue',
            key: 'revenue',
            render: (_, record) => record.userProgress.revenue !== null ? "$ " + record.userProgress.revenue : "-"
        },
        {
            title: 'Interviews',
            dataIndex: 'interviews',
            key: 'interviews',
            render: (_, record) => record.userProgress.interviews !== null ? record.userProgress.interviews : "-"
        },
        {
            title: 'Total Goals',
            dataIndex: 'totalGoals',
            key: 'totalGoals',
            render: (_, record) => record.userProgress.totalGoals !== null ? record.userProgress.totalGoals : "-"
        },
        {
            title: 'Completed Goals',
            dataIndex: 'completedGoals',
            key: 'completedGoals',
            render: (_, record) => record.userProgress.completedGoals !== null ? record.userProgress.completedGoals : "-"
        },
        {
            title: 'Accountability',
            dataIndex: 'accountability',
            key: 'accountability',
            render: (_, record) => record.userProgress.accountability !== null ? (record.userProgress.accountability === true ? "Yes" : "No") : "-"
        },
        {
            title: 'Karma Received',
            dataIndex: 'receivedPoints',
            key: 'receivedPoints',
            render: (_, record) => record.karmaPoints.receivedPoints !== null ? record.karmaPoints.receivedPoints : "-"
        },
        {
            title: 'Karma Given',
            dataIndex: 'givenPoints',
            key: 'givenPoints',
            render: (_, record) => record.karmaPoints.givenPoints !== null ? record.karmaPoints.givenPoints : "-"
        },
        {
            title: 'Karma Lost',
            dataIndex: 'lostPoints',
            key: 'lostPoints',
            render: (_, record) => record.karmaPoints.lostPoints !== null ? record.karmaPoints.lostPoints : "-"
        }
    ]
    
    useEffect(() => {
        let mounted = true;
        loadDashboardData().then(res => {
            if (mounted) {
                setFullData(res.participantsData);
                setWeekData(res.participantsData[res.participantsData.length-1]);
                setWeeks(res.weeks);
            }
        });
        return () => {mounted = false};
    },[])

    const loadDashboardData = async () => {
        try {
            setLoading(true);
            const res = (await getDashboardData({programName: selectedProgram})).data;
            for (const i in res.participantsData) {
                res.participantsData[i] = res.participantsData[i].map(user => {
                    user.key = user.uid;
                    return user;
                })
            }
            console.log(res);
            setLoading(false);
            return res;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false);
            return {participantsData: [], weeks: []}
        }
    }
    
    const filterByWeek = async (value) => {
        console.log("Filter by:" + value);
        setWeekData(fullData[value]);
    }

    return(
        <WhiteContainer>
            <FullRow>
                { weeks.length > 0 && 
                    <Select
                        onSelect={filterByWeek}
                        loading={loading}
                        defaultValue={weeks?.length ? weeks[weeks.length-1] : undefined}
                    >
                        {weeks.map((pressedValue)=>
                            <Select.Option value={pressedValue} key={"week-"+pressedValue}>
                                { "Week " + pressedValue }
                            </Select.Option>
                        )}
                    </Select>
                }
            </FullRow>
            <br/>
            <Table
                style={{overflowX: "auto", overflowY: 'hidden'}}
                bordered
                pagination={false}
                loading={loading} 
                columns={columns}
                dataSource={weekData}
                size="small"
            />
        </WhiteContainer>
    )
}

export default AdminBoard;