import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import { Spin, Badge, Popover, Popconfirm, message } from 'antd';
import { LoadingOutlined} from '@ant-design/icons'
import { StyledCalendar, EventsList, PopoverButton } from './styles'
import { SimpleHeader } from './calendarHeader'
import firebase from 'firebase/app';
import 'firebase/functions';

function EventsCalendar() {
    const [loading, setLoading] = useState(false);
    const [events, setEvents] = useState({}); 
    const { userInfo } = useContext(UserContext);
    
    const deleteEvent = firebase.functions().httpsCallable('deleteEvent');
    const retrieveEvents = firebase.functions().httpsCallable('retrieveEvents');

    const handleDeleteEvent = async (item, value) => {
      try {
        setLoading(true);
        await deleteEvent({eventId: item.id});

        let year = value.year();
        let month = value.month();
        let date = value.date();
        let newEvents = events;

        newEvents[year][month][date] = newEvents[year][month][date].filter(i => i.id !== item.id);
        setEvents(newEvents);
        message.success("Event deleted successfully!");
        setLoading(false);
      }
      catch (error) {
        message.error(error.message);
        console.error(error.message);
      }
    }

    const loadEvents = async (month, year) => {

      setLoading(true);
      let data = (await retrieveEvents({month: month, year: year})).data;

      let tempEvents = events;
      data.forEach(event => {
        let timestamp = new Date(event.date);
        let year = timestamp.getFullYear();
        let month = timestamp.getMonth();
        let date = timestamp.getDate();

        if (tempEvents[year] && tempEvents[year][month] && tempEvents[year][month][date]) {
          if (!tempEvents[year][month][date].some(e => e.id === event.id)) {
            tempEvents[year][month][date].push({ type: event.type, content: event.summary, id: event.id });
          }
        }
        else if (tempEvents[year] && tempEvents[year][month]) {
          tempEvents[year][month][date] = [{ type: event.type, content: event.summary, id: event.id }]
        }
        else if (tempEvents[year]) {
          tempEvents[year][month] = { [date]: [{ type: event.type, content: event.summary, id: event.id }] }
        }
        else {
          tempEvents[year] = { [month]: {[date] : [{ type: event.type, content: event.summary, id: event.id }] } }
        }

      });
      setLoading(false);
      return tempEvents
    }


    const getListData = (value) => {
      let year = value.year();
      let month = value.month();
      let date = value.date();

      if (events[year] && events[year][month] && events[year][month][date]) {
        return events[year][month][date]
      }
      else {
        return []
      }
    }

    const eventPopoverContent = (item, value) => (<> 
      <Badge status={item.type} text={item.content} /> 
      { userInfo.claims.admin && 
        <Popconfirm
          placement="topRight"
          title="Are you sure you want to delete this event?"
          onConfirm={()=>handleDeleteEvent(item, value)}
          okType="danger"
          okText="Yes"
          cancelText="No"
          okButtonProps={{size: "middle"}}
          cancelButtonProps={{size: "middle"}}
          overlayStyle={{overflowWrap:"break-word", width: "200px"}}
          arrowPointAtCenter
        >
            <PopoverButton danger>Delete</PopoverButton> 
        </Popconfirm> }
    </>)
    

    const dateCellRender = (value) => {

      const listData = getListData(value);
      return (
        <EventsList>
          {listData.map(item => (
            <Popover
              key={item.id+"pop"}
              content={eventPopoverContent(item, value)}
            >
              <li key={item.id}>
                <Badge status={item.type} text={item.content} />
              </li>
            </Popover>
          ))}
        </EventsList>
      );
    }


    const onPanelChange = (date, mode) => {

      if (events[date.year()] && events[date.year()][date.month()]) return;

      loadEvents(date.month(), date.year()).then(newEvents => setEvents(newEvents));
    }

    useEffect(() => {
        let today = new Date()
        let mounted = true
        loadEvents(today.getMonth(), today.getFullYear()).then(newEvents => {if(mounted) setEvents(newEvents)})

        return () => {mounted = false};
    },[])

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <Spin indicator={spinnerIcon} spinning={loading}>
          <StyledCalendar dateCellRender={dateCellRender} headerRender={SimpleHeader} onPanelChange={onPanelChange}/>

        </Spin>
    )
}

export default EventsCalendar