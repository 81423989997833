import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow, FeedbackCol } from './styles'
import { List, Select, Spin, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import CategoryProgress from './categoryProgress';
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore'

const dummyPresentations = ['1', '2', '3']
const dummyFeedback = [ {feedback: "This is the first feedback!", date: Date.now(), presentationNum: '1'},
                        {feedback: "This is the second feedback!", date: Date.now(), presentationNum: '1'},
                        {feedback: "This is the third feedback!", date: Date.now(), presentationNum: '2'},
                        {feedback: "This is the fourth feedback!", date: Date.now(), presentationNum: '2'},
                        {feedback: "This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! This is a long feedback! ", date: Date.now(), presentationNum: '3'},
                        {feedback: "This is the sixth feedback!", date: Date.now(), presentationNum: '3'},
]
const dummyScores = {Cat1: 3.4, Cat2: 4, Cat3: 5, Cat4: 1, Cat5: 0.3, Cat6: 2.5, Cat7: 4.8, Cat8: 1.9}


function Results() {
    const [ loading, setLoading ] = useState(true);
    const [ presentations, setPresentations ] = useState(['All Presentations']);
    const [ scores, setScores ] = useState({});
    const [ data, setData ] = useState([]);
    const [ fullFeedback, setFullFeedback ] = useState([]);
    //var fullFeedback;

    const getPresentationsResults = firebase.functions().httpsCallable('getPresentationsResults');

    useEffect(()=>{
        let mounted = true;
        loadData().then(data => {
            if (mounted) {
                setPresentations(data.presentations);
                setFullFeedback(data.feedback);
                setData(data.feedback);
                setScores(data.presentationsScores);
            }
        })
        return () => {mounted = false}
    }, [])

    const loadData = async () => {
        try {
            setLoading(true);
            const res = (await getPresentationsResults()).data;
            const data = { presentations: ['All Presentations', ...res.presentations], feedback: res.presentationsFeedback, presentationsScores: res.presentationsScores}
            setLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false)
            return {presentations: [], feedback: [], presentationsScores: {}}
        }
    }

    const filterPresentations = async (value) => {
        if (value !== "All Presentations") {
            console.log("Filter by:" + value)
            setData(fullFeedback.filter((feed)=> feed.presentationNum === value))
        }
        else {
            console.log("Show all!")
            setData(fullFeedback)
        }
    }

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <WhiteContainer>
            <FullRow separator='true'>
              <h2>Presentations Results</h2>
            </FullRow>
            <Spin indicator={spinnerIcon} spinning={loading}>
                <h3>Results by Categories</h3>
                <FullRow separator='true' gutter={16}>
                    <CategoryProgress scores={scores} loading={loading}/>
                </FullRow>
                <h3>Feedback</h3>
                <Select
                    onSelect={filterPresentations}
                    loading={loading}
                    defaultValue={presentations[0]}
                >
                    {presentations.map((pres)=>
                        <Select.Option value={pres} key={"pres-"+pres}>
                            { pres === "All Presentations" ? pres : "Presentation " + pres }
                        </Select.Option>
                    )}
                </Select>
                <FeedbackCol span={24}>
                    <List
                        itemLayout="horizontal"
                        dataSource={data}
                        renderItem={item => (
                        <List.Item>
                            <List.Item.Meta
                                title={"Presentation " + item.presentationNum}
                                description={item.feedback}
                            />
                            <div>{(new Date(item.date)).toLocaleDateString()}</div>
                        </List.Item>
                        )}
                    />
                </FeedbackCol>
            </Spin>
        </WhiteContainer>
    )
}

export default Results