import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow, HeaderTitleCol, StatTitle } from './styles'
import { Space, Avatar, Table, Col, Statistic, message } from 'antd'
import { AimOutlined, CarryOutOutlined, RiseOutlined, TeamOutlined, TrophyOutlined, UserOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';


function ordinal(i) {
    var j = i % 10;
    var k = i % 100;

    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}


function Board({userInfo, selectedProgram}) {
    const [ loading, setLoading ] = useState(false);
    const [ statLoading, setStatLoading ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ breakdown, setBreakdown ] = useState({})
    const [ rank, setRank ] = useState("-")
    const getLeaderboard = firebase.functions().httpsCallable('getLeaderboard');
    const getPointsBreakdown = firebase.functions().httpsCallable('getPointsBreakdown');

    const columns = [
        {
          title: 'Player',
          dataIndex: 'participant',
          key: 'participant',
          render: (text, record, index) => (
            <Space size="middle">
            { record.photoURL ?
                <Avatar size={32} src={record.photoURL} style={{marginRight: "10px"}}/>
                : <Avatar size={32} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/> }
                {record.displayName || record.alias || "Player " + (index + 1)}
            </Space>
          ),
        },
        {
          title: 'Total Points',
          dataIndex: 'totalPoints',
          key: 'totalPoints',
        },
        {
          title: 'Rank',
          dataIndex: 'rank',
          key: 'rank',
          render: (text, record, index) => (
              ordinal(index + 1)
          )
        }
    ]
    
    useEffect(() => {
        let mounted = true;
        loadLeaderboard().then(data => {if (mounted) setData(data)});
        loadPointsBreakdown().then(data => {if (mounted) setBreakdown(data)})
        return () => {mounted = false}
    },[])

    const loadLeaderboard = async () => {
        try {
            setLoading(true);
            const res = (await getLeaderboard({programName: selectedProgram})).data;
            const data = res.map((user, index) => {
                user.key = user.uid || "row"+index;
                if (user.uid === userInfo?.uid) setRank(ordinal(index + 1))

                return user;
            });
            console.log(data)
            setLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setLoading(false)
            return []
        }
    }

    const loadPointsBreakdown = async () => {
        try {
            if (userInfo?.claims?.admin) return {persistencePoints: "-", karmaPoints: "-", accountabilityPoints: "-"}
            setStatLoading(true);
            const res = (await getPointsBreakdown()).data;
            setStatLoading(false);
            return res;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setStatLoading(false)
            return breakdown;
        }
    }

    return(
        <WhiteContainer>
            <FullRow separator='true' header='true' gutter={16}>
                <HeaderTitleCol xs={24} sm={12} lg={4}>
                    <h2>Leaderboard</h2>
                </HeaderTitleCol>
                <Col xs={12} sm={6} lg={4}>
                    <Statistic 
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Total Points</StatTitle>}
                      prefix={<RiseOutlined/>}
                      value={breakdown.karmaPoints + breakdown.persistencePoints + breakdown.accountabilityPoints} />
                </Col>
                <Col xs={12} sm={6} lg={4}>
                    <Statistic
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Karma Received</StatTitle>}
                      prefix={<AimOutlined/>}
                      value={breakdown.karmaPoints} />
                </Col>
                <Col xs={12} sm={6} lg={4}>
                    <Statistic
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Persistence Points</StatTitle>}
                      prefix={<CarryOutOutlined/>}
                      value={breakdown.persistencePoints} />
                </Col>
                <Col xs={12} sm={6} lg={4}>
                    <Statistic
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Accountability Points</StatTitle>}
                      prefix={<TeamOutlined/>}
                      value={breakdown.accountabilityPoints} />
                </Col>
                <Col xs={12} sm={6} lg={4}>
                    <Statistic
                      valueStyle={{fontSize: "1.2em"}}
                      loading={statLoading}
                      title={<StatTitle>Rank</StatTitle>}
                      prefix={<TrophyOutlined/>}
                      value={rank} />
                </Col>
            </FullRow>
            <Table
                style={{overflowX: "auto", overflowY: 'hidden'}}
                bordered
                pagination={false}
                loading={loading} 
                columns={columns}
                dataSource={data}
                size="small"
            />
        </WhiteContainer>
    )
}

export default Board