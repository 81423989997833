import styled from 'styled-components'
import { Menu } from 'antd';

export const LogoDiv = styled.div `
    float: left;
    width: 120px;
    margin: 14px 0 16px 0;
`

export const LogoImg = styled.img `
    width: 100px;
    display: flex;
`

export const NameDiv = styled.div `
    float: right;
    display: flex;
    height: 31px;
    cursor: pointer;
    h1, h2, h3 {
        color: white;
    }
    span {
        margin: 8px 20px;
    }
    @media (max-width: 1280px) {
        display: none;
    }
`

export const WideMenu = styled(Menu) `
    @media (max-width: 1280px) {
        display: none;
    }
`

export const MenuButton = styled.div `
    border-radius: 3px;
    border: 1px solid lightgray;
    color: lightgray;
    float: left;
    display: flex;
    width: fit-content;
    padding: 5px;
    margin: 20px 15px 20px 0px;
    cursor: pointer;
    @media (min-width: 1281px) {
        display: none;
    }
`

