import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow, ResponsiveForm } from './styles'
import { Avatar, Space, Button, Form, Input, message, Spin, Select, Rate } from 'antd'
import { UserOutlined, LoadingOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';


function Evaluate({selectedProgram}) {
    const [ formLoading, setFormLoading ] = useState(false);
    const [ active, setActive ] = useState(true) 
    const [ participantsLoading, setParticipantsLoading ] = useState(false);
    const [ participants, setParticipants ] = useState([]);
    const [ questions, setQuestions ] = useState([]);
    const [ form ] = Form.useForm()

    const getParticipants = firebase.functions().httpsCallable('getParticipants');
    const getQuestions = firebase.functions().httpsCallable('getQuestions');
    const storePresentationEvaluation = firebase.functions().httpsCallable('storePresentationEvaluation');
    const db = firebase.firestore();

    useEffect(()=>{
        const unsub = db.collection('programs').doc(selectedProgram).collection('presentations').onSnapshot(checkActive);
        return unsub;
    }, [])

    const checkActive = (snapshot) => {
        if (!snapshot.empty) {
            const presData = snapshot.docs[snapshot.size - 1].data();
            setActive(presData.active || false);
        }
        else setActive(false);
    }

    useEffect(() => {
        let mounted = true;
        if (active) {
            loadParticipants().then(data => {if (mounted) setParticipants(data)});
            loadQuestions().then(data => {if (mounted) setQuestions(data)});
        }
        return () => {mounted = false}
    },[active])

    const loadParticipants = async () => {
        try {
            setParticipantsLoading(true);
            const data = (await getParticipants({programName: selectedProgram})).data;
            setParticipantsLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setParticipantsLoading(false)
            return []
        }
    }

    const loadQuestions = async () => {
        try {
            setFormLoading(true);
            const res = (await getQuestions({programName: selectedProgram})).data;
            const data = res.map((q) => {
                q.value = 0;
                return q;
            })

            setFormLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setFormLoading(false)
            return []
        }
    }

    const resetQuestions = () => {
        let cleanQuestions = questions.map((q)=>{
            q.value = 0;
            return q;
        });

        setQuestions([...cleanQuestions])
    }

    const markAsEvaluated = (uid) => {
        let newParticipantList = participants;
        let index = newParticipantList.findIndex(part => part.uid === uid);

        newParticipantList[index].evaluated = true;
        setParticipants(newParticipantList);
    }

    const updateScores = (index, value) => {
        let newQuestions = questions;
        newQuestions[index].value = value;
        setQuestions(newQuestions);
    }

    const getAvgScores = () => {
        var helper = {};
        var result = questions.reduce((result, quest) => {
            var key = quest.category;
            
            if(!helper[key]) {
                helper[key] = {...quest, quantity: 1};
                result.push(helper[key]);
            } else {
                helper[key].value += quest.value;
                helper[key].quantity += 1;
            }

            return result;
        }, []);

        return result.map((q) => {
            return { category: q.category, value: q.value/q.quantity }
        })
    }
    
    const handleSubmit = async (values) => {
        if (questions.some((q)=> q.value === 0 )) return message.error("Please score all questions");

        try {
            setFormLoading(true);
            let data = { evaluatedUserId: values.evaluatedUserId,
                         feedback: values.feedback,
                         questions: getAvgScores()
                       }
            
            console.log(data)
            await storePresentationEvaluation({...data, programName: selectedProgram});
            form.resetFields();
            resetQuestions();
            markAsEvaluated(values.evaluatedUserId);
            message.success("Saved!")
            setFormLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setFormLoading(false)
        }
    }

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <WhiteContainer>
            <FullRow separator='true'>
              <h2>Evaluate Presentations</h2>
            </FullRow>
            <div style={{textAlign:"center"}}>
            { active ? 
            <Spin indicator={spinnerIcon} spinning={formLoading}>
                <ResponsiveForm
                    form={form}
                    layout="vertical"
                    name="evaluatePresentation"
                    onFinish={handleSubmit}
                >   

                    <Form.Item
                        name="evaluatedUserId"
                        rules={[{ required: true, message: 'Select a participant' }]}
                    >
                        <Select placeholder="Select a participant" loading={participantsLoading}>
                            {participants.map((user)=>
                                <Select.Option value={user.uid} key={user.uid} disabled={user.evaluated}>
                                    <Space size="small">
                                        { user.photoURL ?
                                            <Avatar size={25} src={user.photoURL} style={{marginRight: "10px"}}/>
                                            : <Avatar size={25} icon = { <UserOutlined /> } style={{marginRight: "10px"}}/> }
                                        {user.displayName}
                                    </Space>
                                </Select.Option>
                            )}
                        </Select>
                    </Form.Item>
                    
                    { questions.map((quest, i) =>
                            <Form.Item
                                key={"Q"+i}
                                name={"Q"+i}
                                label={quest.question}
                            > 
                                <Rate allowHalf onChange={(val)=>{updateScores(i, val)}}/>
                            </Form.Item>
                    )}

                   <Form.Item
                        name="feedback"
                        rules={[{ required: true, message: 'Enter feedback' }]}
                    >
                        <Input.TextArea placeholder="Feedback"/>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={formLoading}>
                            Submit
                        </Button>
                    </Form.Item>
                </ResponsiveForm>
            </Spin>
            : "There are no presentations in progress... Please come back later!"}
            </div>
        </WhiteContainer>
    )
}

export default Evaluate