import firebase from 'firebase/app';
import 'firebase/functions'
import { notification } from 'antd'

export default class DashboardService {

    // static async getCurrentTasks() {
    //     try {
    //         const currentTasks = await firebase
    //     }
    // }

    static async getCurrentGoals() {
        try {
            const currentGoals = await firebase.functions().httpsCallable('getCurrentGoals')();
            return currentGoals.data
        }
        catch(error) {
            notification.error({
                message: `There was a problem getting the goals`,
                description: 'Please refresh the page and try again'
            })
        }
    }

    static async updateCurrentGoals(newGoals) {
        try {
            const response = await firebase.functions().httpsCallable('updateCurrentGoals')(newGoals)
            return response.data
        }
        catch(error) {
            notification.error({
                message: `There was a problem updating the goals `,
                description: 'Please refresh the page and try again'
            })
        }
    }

    static async getUpcomingEvent() {
        try {
            const events = await firebase.functions().httpsCallable('getUpcomingEvents')()
            return events.data
        }
        catch(error) {
            notification.error({
                message: `There was a problem getting the current events`,
                description: 'Please refresh the page and try again'
            })
        }

    }

    static async getHistoricUserProgress() {
        try {
            const userProgress = await firebase.functions().httpsCallable('getHistoricUserProgress')()
            return userProgress.data
        }
        catch(error) {
            notification.error({
                message: `There was a problem getting your progress`,
                description: 'Please refresh the page and try again'
            })
        }

    }

    static async getHistoricKarma() {
        try {
            const historicKarma = await firebase.functions().httpsCallable('getHistoricKarma')()
            return historicKarma.data

        }
        catch(error) {
            notification.error({
                message: `There was a problem getting your historic karma`,
                description: 'Please refresh the page and try again'
            })
        }

    }

    static async getHistoricPersistence() {
        try {
            const persistence = await firebase.functions().httpsCallable('getHistoricPersistence')()
            return persistence.data
        }
        catch(error) {
            notification.error({
                message: `There was a problem getting your historic persistence points`,
                description: 'Please refresh the page and try again'
            })
        }
    }U



}