import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import { StyledList, StyledSpace, PopoverButton } from './styles'
import { Spin, Space, Popconfirm, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import firebase from 'firebase/app';
import 'firebase/functions';
import 'firebase/firestore';

function DeleteButton({id}) {
    const [loading, setLoading ] = useState(false);
    const { userInfo, selectedProgram } = useContext(UserContext);
    const deleteResource = firebase.functions().httpsCallable('deleteResource');

    const handleDeleteResource = async () => {
        try {
          setLoading(true);
          await deleteResource({resourceId: id, programName: selectedProgram});
          message.success("Resource deleted successfully!");
          setLoading(false);
        }
        catch (error) {
          message.error(error.message);
          console.error(error.message);
          setLoading(false);
        }
    }

    return( userInfo.claims.admin && 
        <Popconfirm
            placement="topRight"
            title="Are you sure you want to delete this resource?"
            onConfirm={()=>handleDeleteResource()}
            okType="danger"
            okText="Yes"
            cancelText="No"
            okButtonProps={{size: "middle"}}
            cancelButtonProps={{size: "middle"}}
            overlayStyle={{overflowWrap:"break-word", width: "200px"}}
            arrowPointAtCenter
        >
                <PopoverButton loading={loading} danger>Delete</PopoverButton> 
        </Popconfirm> 
    )
}

function ResourcesList() {
    const [loading, setLoading] = useState(true);
    const [resources, setResources] = useState([]); 
    const { userInfo, selectedProgram } = useContext(UserContext);
    
    const db = firebase.firestore();

    useEffect(()=>{
        const unsub = db.collection('programs').doc(selectedProgram).collection('resources').onSnapshot(loadResources);
        return unsub;
    }, [])

    const loadResources = (snapshot) => {
        let newData = snapshot.docs.map(doc => {
            const data = doc.data();
            data.key = doc.id;
            data.id = doc.id;
            return data;
        });
        setResources(newData);
        setLoading(false);
    }
    
    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <Spin indicator={spinnerIcon} spinning={loading}>
        <StyledList
            size="large"
            bordered
            dataSource={resources}
            renderItem={item => 
                <StyledList.Item>
                    <Space size="large">
                        <StyledSpace>
                            {item.name}
                        </StyledSpace>
                        {<a href={item.link} target="_blank" rel="noreferrer">Link</a>}
                        { userInfo.claims.admin && 
                            <DeleteButton id={item.id}/>
                        }
                    </Space>
                </StyledList.Item>}
        />
        </Spin>
    )
}

export default ResourcesList

