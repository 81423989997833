import React from 'react'
import { Form, Input, InputNumber, Button, Modal, Tooltip } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';


function CreateProgramModal({visible, createNewProgram, cancelNewProgram}){

    const [ form ] = Form.useForm();

    const handleOk = async () => {
        try {
            const values = await form.validateFields()
            await createNewProgram(values)
            form.resetFields()
        }
        catch (error) {
            console.error(error)
        }
    }

    const handleCancel = async () => {
        form.resetFields()
        cancelNewProgram()
    }

   return(
      <Modal
        style={{top:"40px"}}
        visible={visible}
        title="New Program Information"
        onOk = {handleOk}
        onCancel = {handleCancel}
    >
        <Form
            form={form}
            preserve={false}
            layout="vertical"
            style={{width: "100%"}}
        >
            <Form.Item
                label = {<b>Program Name (Name used for internal reference)</b>}
                name='programName'
                rules={[{ required: true, message: "Program name required"}]}
            >
                <Input placeholder = 'The amazing new accelerator' />
            </Form.Item>
            <Form.Item
                label = {<b>How many points will each Accountability Group Meeting be worth?</b>}
                name='accountabilityValue'
                initialValue={25}
                rules={[{ required: true, message: "Accountability value required" }]}
            >
                <InputNumber
                    style={{width:"100%"}}
                    min={0}
                />
            </Form.Item>
            <Form.Item
                label = {<b>How much weight should the facilitators have over the total presentation points?</b>}
                name='facilitatorsWeight'
                initialValue={0.1}
                rules={[{ required: true, message: "Facilitators weight required" }]}
            >
                <InputNumber
                    style={{width:"100%"}}
                    min={0}
                    max={1}
                />
            </Form.Item>
            
            <Tooltip title = 'This means that the overall result that any participant receives from his/her peers will be 90% of his/her total score'>
                <Form.Item
                    label = {<b>What weight should the participant evaluations have over the total presentations points?</b>}
                    name='participantsWeight'
                    initialValue={0.7}
                    rules={[{ required: true, message: "Participants weight required" }]}
                >
                        <InputNumber
                            style={{width:"100%"}}
                            min={0}
                            max={1}
                            initialValue
                        />
                </Form.Item>
            </Tooltip>
            <Tooltip title = 'This means that the overall result that any participant receives from graduates of past cohorts will be 20% of his/her total score'>
                <Form.Item
                    label = {<b>What weight should the graduate evaluations have over the total presentations points?</b>}
                    name='graduatesWeight'
                    initialValue={0.2}
                    rules={[{ required: true, message: "Participants weight required" }]}
                >
                        <InputNumber
                            style={{width:"100%"}}
                            min={0}
                            max={1}
                            initialValue
                        />
                </Form.Item>
            </Tooltip>
            <Form.Item
                label = {<b>How many weeks should a Persistence Period have?</b>}
                name='persistencePeriodDuration'
                initialValue={2}
                rules={[{ required: true, message: "Persistence Period Duration required" }]}
            >
                <InputNumber
                    style={{width:"100%"}}
                    min={0}
                />
            </Form.Item>
            <Form.Item
                label = {<b>Set the presentations categories here</b>}
                style={{marginBottom: 0}}
                required
            >
                <Form.List
                    name="categories"
                    rules={[{ validator: async (_, categories) => {
                                if (!categories || categories.length < 1)
                                    return Promise.reject(new Error('Set at least 1 category'));
                            } 
                            }]}
                >
                    {(fields, { add, remove }, { errors }) => (
                    <>
                        {fields.map((field, index) => (
                        <Form.Item
                            key={field.key}
                        >
                            <Form.Item
                            {...field}
                            rules={[
                                {
                                required: true,
                                whitespace: true,
                                message: "Please fill the category",
                                },
                            ]}
                            noStyle
                            >
                                <Input placeholder={`Category ${index+1}`} style={{ width: '94%' }} />
                            </Form.Item>
                            <MinusCircleOutlined
                                style={{marginLeft: "10px"}}
                                onClick={() => remove(field.name)}
                            />
                        </Form.Item>
                        ))}
                        <Form.Item style={{marginBottom: 0}}>
                        <Button
                            type="dashed"
                            onClick={() => add()}
                            style={{ width: '40%' }}
                            icon={<PlusOutlined />}
                        >
                            Add category
                        </Button>
                        <Form.ErrorList errors={errors} />
                        </Form.Item>
                    </>
                    )}
                </Form.List>
            </Form.Item>
        </Form>
      </Modal>
   )
}

export default CreateProgramModal