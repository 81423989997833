import { StarOutlined } from '@ant-design/icons'
import { Progress } from 'antd'
import React from 'react'
import { FullRow, ProgressCol } from './styles'

function CategoryProgress({scores}) {

    return( <>
          <FullRow gutter={16} style={{minHeight: "100px", width: "100%"}}>
            {Object.keys(scores).map((cat)=> 
              <ProgressCol xs={12} sm={6} md={6} lg={5} xl={3} key={"Progress-"+cat}>
                <Progress
                  type="circle"
                  strokeColor={{
                    '0%': '#108ee9',
                    '100%': '#87d068'
                  }}
                  percent={(scores[cat] / 5)*100}
                  format={() => <> {`${scores[cat] % 1 !== 0 ? scores[cat].toFixed(2) : scores[cat]}/5`} <StarOutlined/></>}
                />
                <h3 style={{textTransform: "capitalize"}}>{cat.replace("-"," ")}</h3>
              </ProgressCol>
            )}
          </FullRow>
    </>)
}

export default CategoryProgress