import React, { useState } from 'react'
import { Avatar, Col, Modal } from 'antd'
import { MailOutlined, UserOutlined, CloudOutlined, InstagramOutlined, PhoneOutlined } from '@ant-design/icons';
import { CardContainer, FullRow, QuoteP } from './styles'

function UserCard({data}){
   const [ isModalVisible, setIsModalVisible ] = useState(false);

   const showProfileDetails = () => {
      setIsModalVisible(true);
   };

   const handleClose = () => {
      setIsModalVisible(false);
   };

   return(
      <>
      <CardContainer onClick={showProfileDetails}>
         <FullRow>
            <Col span={8}>
            { data.photoURL ? <Avatar size = { 64 } src={data.photoURL} />
                           : <Avatar size = { 64 } icon = { <UserOutlined /> }/> }
            </Col>
            <Col span={16} >
               <h3>{data.displayName}</h3>
               <p>{data.idea}</p>
            </Col>
         </FullRow>
         <FullRow bottom='true'>
            <QuoteP>"{data.whyStatement}"</QuoteP>
         </FullRow>
      </CardContainer>
      <Modal visible={isModalVisible} onCancel={handleClose} footer={null} title="">
         <FullRow>
            <Col span={8}>
            { data.photoURL ? <Avatar size = { 128 } src={data.photoURL} />
                           : <Avatar size = { 128 } icon = { <UserOutlined /> }/> }
            </Col>
            <Col span={16} >
               <h1>{data.displayName}</h1>
               <p >{data.idea}</p>
            </Col>
         </FullRow>
         <FullRow bottom='true'>
            <Col span={12}>
               <h3>Contact Info</h3>
               <p><MailOutlined/>{` ${data.email}`}</p>
               {data.phoneNumber && <p><PhoneOutlined/>{` ${data.phoneNumber}`}</p>}
               {data.websiteURL && <p><CloudOutlined/>{` ${data.websiteURL}`}</p>}
               {data.instagram && <p><InstagramOutlined/>{` ${data.instagram}`}</p>}
            </Col>
            <Col span={12}>
               <h3>Why Statement</h3>
               <QuoteP>"{`${data.whyStatement}`}"</QuoteP>
            </Col>
         </FullRow>
      </Modal>
      </>
   )
}

export default UserCard