import styled from 'styled-components'
import { Calendar, Menu, List, Space, Layout, Button } from 'antd'

export const Container = styled.div `
    display: flex;
    width: 100%;
    min-height: 100vh
`

export const StyledSider = styled(Layout.Sider)
`
    width: 256px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    .ant-layout-sider-zero-width-trigger {
        top: 0
    }
`

export const StyledMenu = styled(Menu)
`
    padding: 20px 0px 0px 0px;
    box-shadow: 4px 0px 5px rgba(0,0,0,0.1);
    font-size: 16px;
    width: 200px;
    position: relative;

    .ant-menu-item {
        height: 60px;
        display: flex;
        padding-left: 25px;
        align-items: center;
        
        &.back{
            position: absolute;
            bottom: 0;
            border-top: 1px solid rgba(0,0,0,0.1);
            width: 100%;
            padding-left: 50px;
        }
    }
`

export const Content = styled.div `
    border-radius: 8px;
    padding: 20px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
`

export const WhiteContainer = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
    width: 80%;
`

export const StyledCalendar = styled(Calendar)`
    border: 1px solid lightgray;
    border-radius: 4px;
    box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
    padding: 0 20px;
    margin: 10px 40px;
`

export const EventsList = styled.ul `
    margin: 0;
    padding: 0;
    list-style: none;

    .ant-badge-status {
        width: 100%;
        overflow: hidden;
        font-size: 12px;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`

export const StyledList = styled(List)
`
    background-color: white;
    margin: 40px;
`

export const StyledSpace = styled(Space)
`
    width: 30vw;
`

export const PopoverButton = styled(Button)
`
    margin-left: 10px;
`