import styled from 'styled-components'
import { Row } from 'antd'

export const WhiteContainer = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    padding: 20px 40px;
    margin-top: 10px;
    width: 100%;
    @media (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`

export const FullRow = styled(Row)
`
    border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
    margin-bottom: ${props => props.separator ? "30px" : "0px"};
    margin-top: ${props => props.bottom ? "15px" : "0px"};

    padding-bottom: ${props => props.header ? "15px" : "0px"};

    @media (max-width: 768px) {
        margin-left: ${props => props.header ? "30px" : "0px"}
    }
`