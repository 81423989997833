import React, { useState, useEffect } from 'react'
import { WhiteContainer, FullRow, StyledCard } from './styles'
import { Col, message, Spin, Tooltip } from 'antd'
import firebase from 'firebase/app';
import 'firebase/functions';
import AvgInterviewsChart from './avgInterviewsChart';
import AvgKarmaChart from './avgKarmaChart';
import AvgPersistenceChart from './avgPersistenceChart';

function Benchmarks() {
    const [ interviewsLoading, setInterviewsLoading ] = useState(true);
    const [ karmaLoading, setKarmaLoading ] = useState(true);
    const [ persistenceLoading, setPersistenceLoading ] = useState(true);
    const [ interviewData, setInterviewData ] = useState({});
    const [ karmaData, setKarmaData ] = useState({});
    const [ persistenceData, setPersistenceData ] = useState({});

    const getInterviewsBenchmark = firebase.functions().httpsCallable('getInterviewsBenchmark');
    const getKarmaPointsBenchmark = firebase.functions().httpsCallable('getKarmaPointsBenchmark');
    const getPersistencePointsBenchmark = firebase.functions().httpsCallable('getPersistencePointsBenchmark');

    useEffect(() => {
        let mounted = true;
        loadInterviews().then(data => {if (mounted) setInterviewData(data)});
        loadKarma().then(data => {if (mounted) setKarmaData(data)});
        loadPersistence().then(data => {if (mounted) setPersistenceData(data)});

        return () => {mounted = false}
    },[])

    const loadInterviews = async () => {
        try {
            setInterviewsLoading(true);
            const res = (await getInterviewsBenchmark()).data;
            // Fill the first weeks in case the user had a late start
            let interviews = [...Array.from({length: Number(res.userStartWeek)}, ()=>0), ...res.userHistoricInterviews]

            let data = { avgInterviews: res.participantsHistoricInterviewsAvg,
                         interviews: interviews,
                         weeks: Array.from({length: res.participantsHistoricInterviewsAvg.length}, (_, i) => i)
                       }

            console.log(data);
            setInterviewsLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setInterviewsLoading(false)
            return []
        }
    }

    const loadKarma = async () => {
        try {
            setKarmaLoading(true);
            const res = (await getKarmaPointsBenchmark()).data;
            // Fill the first weeks in case the user had a late start
            let karma = [...Array.from({length: Number(res.userStartWeek)-1}, ()=>0), ...res.userHistoricKarmaPoints]

            let data = { avgKarma: res.participantsHistoricKarmaPointsAvg,
                         karma: karma,
                         weeks: Array.from({length: res.participantsHistoricKarmaPointsAvg.length}, (_, i) => i+1)
                       }

            console.log(data);
            setKarmaLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setKarmaLoading(false)
            return []
        }
    }

    const loadPersistence = async () => {
        try {
            setPersistenceLoading(true);
            const res = (await getPersistencePointsBenchmark()).data;
            // Fill the first weeks in case the user had a late start
            let persistence = [...Array.from({length: Number(res.userStartPeriod)-1}, ()=>0), ...res.userHistoricPersistencePoints]

            let data = { avgPersistence: res.participantsHistoricPersistencePointsAvg,
                         persistence: persistence,
                         periods: Array.from({length: res.participantsHistoricPersistencePointsAvg.length}, (_, i) => i+1)
                       }

            console.log(data);
            setPersistenceLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setPersistenceLoading(false)
            return []
        }
    }

    return(
        <WhiteContainer>
            <FullRow separator='true' header='true'>
              <h2>Benchmarks</h2>
            </FullRow>
            <FullRow gutter={16}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <Tooltip
                        placement='top'
                        title = "Comparison between your interviews and the average number of interviews the participants had per week"
                    >
                        <StyledCard title="My Customer Interviews vs. Group Avg Interviews" bordered={true}>
                            <Spin spinning={interviewsLoading}>
                                <AvgInterviewsChart xData={interviewData.weeks} customer={interviewData.interviews} group={interviewData.avgInterviews}/>
                            </Spin>
                        </StyledCard>
                    </Tooltip>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <Tooltip
                        placement='top'
                        title = "Comparison between your karma points and the average number of karma points the participants had per week (first week data appears on week 2)"
                    >
                        <StyledCard title="My Karma Points vs. Group Avg Karma Points" bordered={true}>
                            <Spin spinning={karmaLoading}>
                                <AvgKarmaChart xData={karmaData.weeks} customer={karmaData.karma} group={karmaData.avgKarma}/>
                            </Spin>
                        </StyledCard>
                    </Tooltip>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <Tooltip
                        placement='right'
                        title = "Comparison between your persistence points and the average number of persistence points the participants had per 2-week period (first period data appears on week 3)"
                    >
                        <StyledCard title="My Persistence Points vs. Group Avg Persistence Points" bordered={true}>
                            <Spin spinning={persistenceLoading}>
                                <AvgPersistenceChart xData={persistenceData.periods} customer={persistenceData.persistence} group={persistenceData.avgPersistence}/>
                            </Spin>
                        </StyledCard>
                    </Tooltip>
                </Col>
            </FullRow>
        </WhiteContainer>
    )
}

export default Benchmarks