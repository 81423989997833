import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Spin, Row, Col, Checkbox, Badge, Tabs, message, Tooltip } from 'antd'
import { SmallCard, TabsCard, TallCard } from '../styles'
import firebase from 'firebase/app';
import 'firebase/functions'
import InterviewsChart from '../common/interviewsChart';
import RevenueChart from '../common/revenueChart';
import GoalsChart from '../common/goalsChart';
import KarmaChart from '../common/karmaChart';
import PersistenceChart from './persistenceChart';

import DashboardService from '../../../services/DashboardService';
import { getKeyThenIncreaseKey } from 'antd/lib/message';


function ParticipantGrid() {
    const [ goalLoading, setGoalLoading ] = useState(false);
    const [ eventLoading, setEventLoading ] = useState(false);
    const [ progressLoading, setProgressLoading ] = useState(false);
    const [ karmaLoading, setKarmaLoading ] = useState(false);
    const [ persistenceLoading, setPersistenceLoading ] = useState(false);
    const [ weeks, setWeeks ] = useState([])

    const [ events, setEvents ] = useState([]);
    const [ tasks, setTasks ] = useState([]);
    const [goals, setGoals] = useState({
        total: [],
        completed: []
    })
    const [ interviews, setInterviews ] = useState([])
    const [ persistence, setPersistence ] = useState([])
    const [ revenue, setRevenue ] = useState([])
    const [ progressData, setProgressData ] = useState({});
    const [ karma, setKarma ] = useState({});

    const { refresh } = useParams();

    useEffect(() => {
        let mounted = true;

        getGoals()
        getEvents()
        getProgressHistory()
        getKarma()
        getPersistence()

        return () => {mounted = false}
    },[refresh])

    const getGoals = async () => {
        try {
            setGoalLoading(true);
            const res = await DashboardService.getCurrentGoals()
            setTasks(res)
            setGoalLoading(false)
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setGoalLoading(false)
            return []
        }
    }

    const getEvents = async () => {
        try {
            setEventLoading(true);
            const res = await DashboardService.getUpcomingEvent()
            console.log('Events response', res)
            if(res) setEvents(res)
            setEventLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setEventLoading(false)
        }
    }

    const getProgressHistory = async () => {
        try {
            setProgressLoading(true);
            const progress = await DashboardService.getHistoricUserProgress()
            console.log('progress', progress)
            let weeks = progress.historic.goals.map((goal, index)=> index + 1)
            setWeeks(weeks)
            setGoals({
                total: progress.historic.goals,
                completed: progress.historic.totalGoals
            })

            setInterviews(progress.historic.interviews)
            setRevenue(progress.historic.revenue)
            setProgressLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setProgressLoading(false)
            return []
        }
    }
    
    const getKarma = async () => {
        try {
            setKarmaLoading(true);
            const res = await DashboardService.getHistoricKarma()
            console.log('karma', res)
            setKarma([...res.historicKarma])
            // Fill the first weeks in case the user had a late start
            let karma = [...Array.from({length: Number(res.startWeek)-1}, ()=>0), ...res.historicKarma]

            let data = { 
                karma: karma,
                weeks: Array.from({length: karma.length}, (_, i) => i+1)
            }
            setKarma({...data})

            setKarmaLoading(false);
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setKarmaLoading(false)
        }
    }

    const getPersistence = async () => {
        try {
            setPersistenceLoading(true);
            const res = await DashboardService.getHistoricPersistence()
            // Fill the first weeks in case the user had a late start
            let persistence = [...Array.from({length: Number(res.startPeriod)-1}, ()=>0), ...res.historicPersistence]

            let data = { 
                persistence: persistence,
                periods: Array.from({length: persistence.length}, (_, i) => i+1)
            }
            setPersistence({...data})
            setPersistenceLoading(false);
            return data;
        }
        catch (error) {
            message.error(error.message);
            console.error(error);
            setPersistenceLoading(false)
            return []
        }
    }
 
    const handleGoalCheck = async (value, i) => {
        setGoalLoading(true);
        var newGoals = goals;
        newGoals[i].checked = value;
        await DashboardService.updateCurrentGoals({currentGoals: newGoals});
        setGoals(newGoals);
        setGoalLoading(false);
        message.success("Goals Saved!")
    }

    return(
        <div className="site-card-wrapper"> 
            <Row gutter={16}>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <SmallCard title="My Weekly Goals" bordered={true} top='true'>
                        <Spin spinning={goalLoading}>
                            {tasks && tasks.map((task, i)=> 
                                <Row style={{paddingBottom:"10px"}} key={`checkbox-${i}`}>
                                    <Checkbox
                                        defaultChecked={task.checked}
                                        onChange={(e)=>{handleGoalCheck(e.target.checked, i)}}
                                    >
                                        {task.summary}
                                    </Checkbox>
                                </Row> )}
                        </Spin>
                    </SmallCard>
                </Col>
                <Col lg={12} md={24} sm={24} xs={24}>
                    <SmallCard title="Upcoming Events" bordered={true}>
                        <Spin spinning={eventLoading}>
                        {events && events.length > 0 && 
                            events.map((item)=>
                                <li key={item.id}>
                                    <Badge status={item.type} text={`${item.summary} - ${new Date(item.date).toLocaleDateString()}`} />
                                </li>
                        )}
                        {events.length === 0 && !eventLoading && "No upcoming events"}
                        </Spin>
                    </SmallCard>
                </Col>
            </Row>
            <Row gutter={16}>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <TabsCard title="Weekly Progress" bordered={true}>
                        <Spin spinning={progressLoading}>
                            <Tabs defaultActiveKey="interviews">
                                <Tabs.TabPane tab="Interviews" key="interviews">
                                    <InterviewsChart xData={weeks} customer={interviews}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Revenue" key="2">
                                    <RevenueChart xData={weeks} revenue={revenue}/>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Goals" key="3">
                                    <GoalsChart xData={weeks} completed={goals.completed} total={goals.total}/>
                                </Tabs.TabPane>
                            </Tabs>
                        </Spin>
                    </TabsCard>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <TallCard title="Historic Karma" bordered={true}>
                        <Spin spinning={karmaLoading}>
                            {karma.karma && <KarmaChart xData={weeks} karma={karma.karma}/>}
                        </Spin>
                    </TallCard>
                </Col>
                <Col lg={8} md={24} sm={24} xs={24}>
                    <Tooltip
                        placement='top'
                        title = "Your received persistence points per 2-week period (first period data appears on week 3)"
                    >
                        <TallCard title="Historic Persistence" bordered={true}>
                            <Spin spinning={persistenceLoading}>
                                <PersistenceChart xData={persistence.periods} persistence={persistence.persistence} />
                            </Spin>
                        </TallCard>
                    </Tooltip>
                </Col>
            </Row>
        </div>
    )
}

export default ParticipantGrid