import React, { useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import { CheckOutlined, PieChartOutlined, SettingOutlined } from '@ant-design/icons'
import { Container, StyledSider, StyledMenu, Content } from './styles'
import Evaluate from './evaluate'
import Config from './config'
import Results from './results'

function Presentations() {
    const { userInfo, selectedProgram } = useContext(UserContext) 
    const [menuOption, setMenuOption] = useState(userInfo.claims.admin ? 'config' : ( userInfo.claims.participant ? 'results' : 'evaluate'))

    const handleMenuClick = (e) => {
        setMenuOption(e.key)
    }

    return(
        <Container>
            <StyledSider 
                theme='light'
                width={200}
                breakpoint="md"
                collapsedWidth="0"
                zeroWidthTriggerStyle={{border: "1px solid lightgray"}}
                >
                <StyledMenu
                    theme = 'light'
                    onClick = { handleMenuClick }
                    mode = "inline"
                    defaultSelectedKeys={ userInfo.claims.admin ? ['config'] : ( userInfo.claims.participant ? ['results'] : ['evaluate'])}
                >       
                        { userInfo.claims.participant && 
                            <StyledMenu.Item key='results' icon = { <PieChartOutlined /> }>
                                Results
                            </StyledMenu.Item>         
                        }       
                        <StyledMenu.Item key='evaluate' icon = { <CheckOutlined /> }>
                            Evaluate 
                        </StyledMenu.Item>
                        { userInfo.claims.admin && 
                            <StyledMenu.Item key='config' icon = { <SettingOutlined /> }>
                                Configuration
                            </StyledMenu.Item>         
                        }
                </StyledMenu>
            </StyledSider>
            <Content>
                {   
                    menuOption === 'results' ? <Results/> :
                    menuOption === 'evaluate' ? <Evaluate selectedProgram={selectedProgram}/> : 
                    menuOption === 'config' ? <Config selectedProgram={selectedProgram}/> :
                    <></>
                }
            </Content>
        </Container>
    )
}

export default Presentations