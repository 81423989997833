import React, { useContext, useEffect } from 'react'
import { UserContext } from '../../contexts/UserContext'
import { DashboardPage, Container, FullRow } from './styles'
import ParticipantGrid from './participant/participantGrid'
import AdminBoard from './admin/adminBoard'
import GraduateDashboard from './graduate'

function Dashboard() {
    const { userInfo } = useContext(UserContext);

    useEffect(()=> {
        console.log('user information', userInfo)
    },[userInfo])

    return(
        <DashboardPage>
            <Container>
                <FullRow separator='true'>
                    <h2>Your Dashboard</h2>
                </FullRow>
                {userInfo.claims.participant && <ParticipantGrid/>}
                {userInfo.claims.admin && <AdminBoard/>}
                {userInfo.claims.graduate && <GraduateDashboard />}
            </Container>
        </DashboardPage>
    )
}

export default Dashboard