import styled from 'styled-components'

export const SignupPage = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const Container = styled.div`
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 40px 20px 40px;
    margin-top: 10%;
`