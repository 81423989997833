import React from 'react'
import { ChartContainer } from '../styles'
import { Bar } from 'react-chartjs-2'

function GoalsChart({xData, completed, total}) {

    const data = {
      labels: xData,
      datasets: [
        {
          label: 'Completed Goals',
          data: completed,
          backgroundColor: 'rgb(75, 192, 192)',
        },
        {
          label: 'Total Goals',
          data: total,
          backgroundColor: 'rgb(255, 99, 132)',
        }
      ],
    }
    
    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [ {
          scaleLabel: {
            display: true,
            labelString: 'Week'
          } 
        } ],
        yAxes: [{
          ticks: {
              beginAtZero: true
          }
        }]
      }
    }

    return(
          <ChartContainer className="chart-container">
              <Bar data={data} options={options}/>
          </ChartContainer>
    )
}

export default GoalsChart