import styled from 'styled-components'
import { Menu, Layout, Row } from 'antd'

export const Container = styled.div `
    display: flex;
    width: 100%;
`

export const StyledSider = styled(Layout.Sider)
`
    width: 200px;
    .ant-layout-sider-zero-width-trigger {
        top: 0
    }
`

export const StyledMenu = styled(Menu)
`
    padding: 20px 0px 0px 0px;
    box-shadow: 4px 0px 5px rgba(0,0,0,0.1);
    font-size: 16px;
    width: 200px;
    position: relative;

    .ant-menu-item {
        height: 60px;
        display: flex;
        padding-left: 25px;
        align-items: center;
        
        &.back{
            position: absolute;
            bottom: 0;
            border-top: 1px solid rgba(0,0,0,0.1);
            width: 100%;
            padding-left: 50px;
        }
    }
`

export const Content = styled.div `
    border-radius: 8px;
    padding: 20px 60px;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    @media (max-width: 768px) {
        padding-left: 5px;
        padding-right: 5px;
    }
`

export const WhiteContainer = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    padding: 20px 40px;
    margin-top: 10px;
    width: 100%;
`

export const FullRow = styled(Row)
`
    width:100%;
    border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
    margin-bottom: ${props => props.separator ? "30px" : "0px"};
    margin-top: ${props => props.bottom ? "15px" : "0px"};
`