import styled from 'styled-components'
import { Card } from 'antd'

export const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;

    .ant-card {
        width: 49%;
        border-radius: 4px;
        box-shadow: 0px 0px 12px rgba(0,0,0,0.15);

        .ant-card-head-title {
            font-family: 'Roboto condensed', sans-serif !important;
        }

        .ant-card-body {
            display: flex;
            flex-direction: column;

            .ant-checkbox-wrapper {
                margin: 10px 0px;
            }
        }
    }

`

export const ChartCard = styled(Card)`
    border-radius: 4px;
    box-shadow: 0px 0px 12px rgba(0,0,0,0.15);
    width: 30%;
    max-width: 32%;
    max-height: 400px;
`

export const EmptyContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    min-height: 150px;

    h3 {
        /* font-family: 'roboto mono', sans-serif; */
        margin: -40px 0px 0px 0px;
        padding: 0;
    }
`

export const EventListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 15px;

    h4{
        font-size: 16px;
    }

    p{
        font-size: 14px;
    }
`