import React, { useState } from 'react'
import { Row, Col, Form, Input, Select, Badge, DatePicker, Button, Spin, message } from 'antd'
import { EditOutlined, LoadingOutlined} from '@ant-design/icons'
import { WhiteContainer } from './styles'

function EventForm({onFinish}) {
    const [ form ] = Form.useForm();
    const [ loader, setLoader ] = useState(false);

    const handleSubmit = async (data) => {
        setLoader(true);
        try {
            await onFinish(data);
            message.success("Event added successfully");
            form.resetFields();
        }
        catch (error) {
            message.error(error.message);
        }
        setLoader(false);
    }

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <Spin indicator={spinnerIcon} spinning={loader}>
            <WhiteContainer>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish ={ handleSubmit }
                        style={{width: "100%"}}
                        >
                        <Row gutter={16}>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    label = {<b>Date</b>}
                                    name='date'
                                    rules={[{ required: true }]}
                                >
                                    <DatePicker format="MM/DD/YYYY" style={{cursor: "pointer", width:"100%"}}/>
                                </Form.Item>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Form.Item
                                    label = {<b>Color</b>}
                                    name='type'
                                    rules={[{ required: true }]}
                                    initialValue="success"
                                >
                                    <Select defaultValue="success">
                                        <Select.Option value="success"><Badge status="success" text="Green" /></Select.Option>
                                        <Select.Option value="processing"><Badge status="processing" text="Blue" /></Select.Option>
                                        <Select.Option value="warning"><Badge status="warning" text="Orange" /></Select.Option>
                                        <Select.Option value="error"><Badge status="error" text="Red" /></Select.Option>
                                        <Select.Option value="default"><Badge status="default" text="Gray" /></Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    label = {<b>Summary</b>}
                                    name='summary'
                                    rules={[{ required: true }]}
                                >
                                    <Input prefix={<EditOutlined />} placeholder="Event summary"/>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item style={{textAlign: "center"}}>
                                    <Button htmlType = 'submit' type = 'primary' size = 'large'>Submit</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
            </WhiteContainer>
        </Spin>
    )
}

export default EventForm
