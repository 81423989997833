import React, { useState, useEffect, useContext} from 'react'
import { useHistory } from 'react-router-dom'
import { UserContext } from '../../contexts/UserContext' 
import firebase from 'firebase/app'
import 'firebase/functions'
import 'firebase/storage'
import { Spin, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import SettingsForm from './form'

function UserSettings() {
    const storeUserInfo = firebase.functions().httpsCallable('storeUserInfo');
    const retrieveUserInfo = firebase.functions().httpsCallable('retrieveUserInfo');

    const {userInfo, reloadUser} = useContext(UserContext) 
    const [infoLoading, setInfoLoading] = useState(false)
    const [userExtraInfo, setUserExtraInfo] = useState({})
    const history = useHistory();
    
    const loadExtraInfo = () => {
        setInfoLoading(true);

        retrieveUserInfo({
        }).then(result => {
            setUserExtraInfo(result.data);
            setInfoLoading(false);
        }).catch(error => {
            console.log(error);
        })
    }

    useEffect(() => {
        loadExtraInfo();
    },[])

    const handleImage = async (file) => {
        if (file.file) {
            try {
                const snapshot = await firebase.storage().ref('users/' + userInfo.uid + '/profile').put(file.file);
                var imgURL = await snapshot.ref.getDownloadURL();
                imgURL = imgURL.split("&")[0];

                await storeUserInfo({ photoURL: imgURL+"&"+Date.now() })
                await reloadUser();
                message.success('Upload successful!');
                console.log('Upload successful!')
                return 'Success!'
            }
            catch (error) {
                console.error(error.message);
                message.error('Upload failed, please try again!');
            }
        }
    }

    const onFinish = async (values) => {
        try {
            let data = {
                displayName: values.displayName,
                email: values.email,
                phoneNumber: values.phoneNumber,
                instagram: values.instagram,
                websiteURL: values.websiteURL,
                idea: values.idea,
                whyStatement: values.whyStatement
            }
            if (userInfo?.claims?.participant) data.alias = values.alias;

            await storeUserInfo(data);
    
            await reloadUser();
            message.success('Profile updated!');
        }
        catch (error) {
            if (error.code === "auth/user-token-expired") {
                message.success('Please sign in with your new email');
                await new Promise(r => setTimeout(r, 10));
                history.push('/login');
            }
            else {
                console.error(error);
                message.error('Update failed, please try again!');
            }
        }

    } 

    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <Spin indicator={spinnerIcon} spinning={infoLoading}>
            <SettingsForm
                onFinish = { onFinish } 
                onImageUpload = { handleImage }
                data = { userExtraInfo }
            />
        </Spin>
    )
}

export default UserSettings