import React, { useState, useEffect, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import firebase from 'firebase/app'
import 'firebase/functions'
import { Spin, List, message } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { FullRow, CommunityPage, Container } from './styles'
import UserCard from './card'


function CommunityList() {
    const retrieveMembers = firebase.functions().httpsCallable('retrieveMembers');

    const { selectedProgram } = useContext(UserContext)
    const [ members, setMembers ] = useState([]);
    const [ loading, setLoading ] = useState(true);

    const getMembers = async () => {
      try {
        setLoading(true);
        const list = (await retrieveMembers({programName: selectedProgram})).data;
        // Preprocessing?
        console.log(list)
        setLoading(false);
        return list;
      }
      catch (error) {
        console.error(error.message);
        message.error(error.message);
      }
    };

    useEffect(()=>{
      let mounted = true;
      getMembers().then(list => { if (mounted) {setMembers(list)} });

      return () => {mounted = false}
    }, [])
    
    const spinnerIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

    return(
        <CommunityPage>
            <Container>
              <FullRow separator='true'>
              <h2>Community</h2>
              </FullRow>
              <Spin indicator={spinnerIcon} spinning={loading}>
              <FullRow>
                <List
                    grid={{
                      xs: 1,
                      sm: 1,
                      md: 2,
                      lg: 3,
                      xl: 3,
                      xxl: 4,
                    }}
                    itemLayout="horizontal"
                    style={{width: "100%"}}
                    size="large"
                    // pagination={{
                    //   onChange: () => {},
                    //   pageSize: 9,
                    //   showSizeChanger: false
                    // }}
                    dataSource={members}
                    renderItem={member => (
                      <UserCard data={member}/>
                    )}
                  />
              </FullRow>
              </Spin>
            </Container>
        </CommunityPage>
    )
}

export default CommunityList