import styled from 'styled-components';
import { Row, Button } from 'antd'

export const UploadButton = styled(Button)
`
    margin-top: 15px;

`
export const SettingsPage = styled.div `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
`

export const Container = styled.div `
    background-color: white;
    border: 1px solid lightgray;
    display: flex;
    flex-direction: column;
    padding: 20px 40px 20px 40px;
    margin-top: 20px;
    width: 70%;

    h1 {
        margin-bottom: 25px;
    }
`

export const FullRow = styled(Row)
`
    width:100%;
    border-bottom: ${props => props.separator ? "1px solid rgba(0,0,0,0.1)" : "0px"};
    margin-bottom: ${props => props.separator ? "30px" : "0px"};
`