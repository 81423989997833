import React, { useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import CommunityList from './communityList'
import UserManagement from './userManagement'
import { TeamOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { MenuContainer, StyledSider, StyledMenu, Content } from './styles'


function Community() {
    const [menuOption, setMenuOption] = useState('community')
    const { userInfo } = useContext(UserContext) 

    const handleMenuClick = (e) => {
        setMenuOption(e.key)
    }

    return(
        <MenuContainer>
          <StyledSider 
              theme='light'
              width={200}
              breakpoint="md"
              collapsedWidth="0"
              zeroWidthTriggerStyle={{border: "1px solid lightgray"}}
              >
              <StyledMenu
                  theme = 'light'
                  onClick = { handleMenuClick }
                  mode = "inline"
                  defaultSelectedKeys={['community']}
              >
                      <StyledMenu.Item key='community' icon = { <TeamOutlined /> }>
                          Community 
                      </StyledMenu.Item>
                      { userInfo.claims.admin && 
                          <StyledMenu.Item key='delete' icon = { <MinusCircleOutlined /> }>
                              Delete
                          </StyledMenu.Item>         
                      }
              </StyledMenu>
          </StyledSider>
        <Content>
            {
                menuOption === 'community'
                    ? <CommunityList/> 
                    : userInfo.claims.admin && <UserManagement/>
            }
        </Content>
      </MenuContainer>
    )
}

export default Community