import React from 'react'
import { ChartContainer } from '../styles'
import { Line } from 'react-chartjs-2'

function PersistenceChart({xData, persistence}) {

    const data = {
      labels: xData,
      datasets: [
        {
          label: 'Persistence Points',
          data: persistence,
          backgroundColor: [
            '#E8F3F5'
          ],
          borderColor: [
              '#45ACC2'
          ],
        }
      ],
    }
    
    const options = {
      maintainAspectRatio: false,
      scales: {
        xAxes: [ {
          scaleLabel: {
            display: true,
            labelString: 'Period'
          } 
        } ],
        yAxes: [{
          ticks: {
              beginAtZero: true
          }
        }]
      }
    }

    return(
          <ChartContainer className="chart-container">
              <Line data={data} options={options}/>
          </ChartContainer>
    )
}

export default PersistenceChart