import React, { useState, useContext } from 'react'
import { UserContext } from '../../contexts/UserContext' 
import { BarChartOutlined, TrophyOutlined } from '@ant-design/icons'
import { Container, StyledSider, StyledMenu, Content } from './styles'
import Board from './board'
import Benchmarks from './benchmarks'

function Leaderboard() {
    const [menuOption, setMenuOption] = useState('leaderboard')
    const { userInfo, selectedProgram } = useContext(UserContext) 

    const handleMenuClick = (e) => {
        setMenuOption(e.key)
    }

    return(
        <Container>
            <StyledSider 
                theme='light'
                width={200}
                breakpoint="md"
                collapsedWidth="0"
                zeroWidthTriggerStyle={{border: "1px solid lightgray"}}
                >
                <StyledMenu
                    theme = 'light'
                    onClick = { handleMenuClick }
                    mode = "inline"
                    defaultSelectedKeys={['leaderboard']}
                >
                        <StyledMenu.Item key='leaderboard' icon = { <TrophyOutlined /> }>
                            Leaderboard 
                        </StyledMenu.Item>
                        { userInfo?.claims?.participant && 
                            <StyledMenu.Item key='benchmarks' icon = { <BarChartOutlined /> }>
                                Benchmarks
                            </StyledMenu.Item>
                        }
                </StyledMenu>
            </StyledSider>
            <Content>
                {
                    menuOption === 'benchmarks' ? <Benchmarks/> :
                    <Board userInfo={userInfo} selectedProgram={selectedProgram}/> 
                } 
            </Content>
        </Container>
    )
}

export default Leaderboard